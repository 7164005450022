import { CommonButton } from "@Components/common/CommonButton";
import { SLIDER_ISBEGINNING, SLIDER_ISENDING } from "@Constants/index";
import { mq, stripButton } from "@Utils/StyleUtil";
import styled, { css } from "styled-components";

export const SectionHeader = styled.div<{
  readonly packages?: boolean;
}>`
  display: flex;
  align-items: baseline;
  margin-bottom: 8px;
  padding-left: 10px;
  width: 100%;
  ${mq(1024)} {
    margin-bottom: 16px;
    padding-left: 0;
  }
`;

export const HeaderIcon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.red};
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  img {
    width: 9.72px;
    height: 7.16px;
    object-fit: fill;
  }
`;

export const SectionTitle = styled.h6`
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  font-size: ${({ theme }) => theme.fontsizes.h6};
  color: ${({ theme }) => theme.colors.nero};
  line-height: 36px;
  margin-right: 16px;
  text-transform: capitalize;
`;

export const ShowAll = styled.button`
  ${stripButton};
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  color: ${({ theme }) => theme.colors.gray2};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ShowAllArrow = styled.div`
  margin-left: 9.45px;
  transform: none /*rtl: scale(-1, 1) */;
`;

export const ArrowsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: auto;
  ${mq(992)} {
    display: none;
  }
`;
export const ArrowBtn = styled(CommonButton)`
  padding: 0;
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  transition:
    0.2s ease opacity,
    0.2s ease background-color;
  box-shadow: none;
  & > svg:lang(ar) {
    transform: rotate(180deg);
  }
  ${({ theme }) => css`
    background-color: ${theme.colors.gray6};
    &:hover {
      background-color: ${theme.colors.gray6};
      box-shadow: none;
    }
    &:active {
      background: ${theme.colors.gray6};
    }
    &:disabled,
    &[disabled] {
      cursor: not-allowed;
      background-color: ${theme.colors.gray6};
      svg {
        opacity: 0.2;
      }
    }
  `}
  &.inverse-dir {
    svg {
      transform: scale(-1, 1) /*rtl: none */;
    }
  }
`;

export const SliderWrapper = styled.div<{
  readonly packages?: boolean;
  readonly reviews?: boolean;
  readonly hasShadow?: boolean;
  readonly shadowposition?: string;
  readonly packageShadow?: boolean;
  readonly LiveShadow?: boolean;
  readonly reviewsShadow?: boolean;
}>`
  margin-bottom: 40px;
  .swiper {
    overflow: visible;
    padding: ${({ packages, shadowposition }) =>
      packages ? (shadowposition === SLIDER_ISENDING ? "8px 10px 16px 0" : "8px 0 16px 10px") : "8px 0 0 10px"};
    ${({ hasShadow, shadowposition, packageShadow, LiveShadow, reviewsShadow }) =>
      hasShadow &&
      css`
        position: relative;
        overflow: hidden;
        transform: ${shadowposition === SLIDER_ISENDING ? "translateX(10px)" : "translateX(0px)"};
        transition: transform 0.1s ease;
        &::after {
          content: "";
          position: absolute;
          top: 8px;
          ${shadowposition === SLIDER_ISBEGINNING
            ? css`
                right: 0;
              `
            : css`
                left: 0;
              `}
          width: 20px;
          ${packageShadow
            ? css`
                height: 308px;
              `
            : LiveShadow
              ? css`
                  height: 184px;
                `
              : reviewsShadow
                ? css`
                    height: 216px;
                  `
                : css`
                    height: 100%;
                  `}
          background: ${shadowposition === SLIDER_ISBEGINNING
            ? "linear-gradient(90deg,rgba(26, 26, 26, 0) 0%,rgba(26, 26, 26, 0.2) 100%)"
            : "linear-gradient(-90deg,rgba(26, 26, 26, 0) 0%,rgba(26, 26, 26, 0.2) 100%)"};
          z-index: 200;
          ${mq(1024)} {
            padding: 0;
          }
        }
      `}
  }

  .swiper-slide {
    width: 262px !important;
  }
  ${({ packages }) =>
    packages &&
    css`
      .swiper-slide {
        width: auto !important;
        ${mq(992)} {
          width: auto !important;
        }
      }
    `}
`;

export const SubjectWrapper = styled.article`
  font-size: 10px;
  ${mq(992)} {
    font-size: 7.5px;
  }
`;

export const CategoryWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 16px;
  overflow-x: scroll;
  margin-left: 10px;
  ${mq(1024)} {
    margin-left: 0;
  }
  /* ${mq(576)} {
    flex-wrap: wrap;
  } */
`;

export const CaregoryItem = styled.p`
  padding: 12px;
  background-color: ${({ theme }) => theme.colors.gray6};
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.body14};
  color: ${({ theme }) => theme.colors.gray1};
  border-radius: 6px;
  line-height: 24px;
  cursor: pointer;
  white-space: nowrap;
`;
