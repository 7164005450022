import { PageWrapper } from "@Components/DefaultLayout/DefaultLayout.styled";
import { FooterWrapper } from "@Components/Footer/Footer.styled";
import { BaimsAIInner } from "@Components/HomePage/BaimsAI/BaimsAI.styled";
import { ArrowBtn, CaregoryItem } from "@Components/HomePage/CategorySection/CategorySection.styled";
import {
  BreadcrumbsHomeWrapper,
  Wrapper,
} from "@Components/HomePage/HomeEducationalDetails/HomeEducationalDetails.styled";
import { ArrowsWrapper } from "@Components/LandingPage/CoursesSection/CoursesSection.styled";
import { BoxWrapper } from "@Components/LandingPage/LandingPage.styled";
import {
  TeacherCardWrapper,
  TeacherName,
  TeacherStats,
} from "@Components/LandingPage/TeachersSection/TeachersSection.styled";
import { BurgerMenu, DownloadAppLink, Header, LogoLink } from "@Components/Navbar/Navbar.styled";
import { DiscountBadge } from "@Components/PackageCardContent/PackageCardContent.styled";
import { MethodsWrapper } from "@Components/PaymentBox/components/TamaraPopup/TamaraPopup.styled";
import { SubjectCardImagePlaceHolder } from "@Components/SearchPage/components/ResultsCards/ResultsCards.styled";
import { SubjectContentPlaceHolder } from "@Components/SubjectCardContent/PackageCard/PackageCard.styled";
import { SubjectCardImagePlaceholder } from "@Components/SubjectCardContent/SubjectCard/SubjectCard.styled";
import { DesktopLayout, PaymentBtn } from "@Components/SubjectPage/SubjectPage.styled";
import { ButtonContainer } from "@Components/common/Button";
import { CommonButton } from "@Components/common/CommonButton";
import { FOUNDING_DAY_THEME, KSA_COUNTRY_CODE, NATIONAL_DAY_THEME } from "@Constants/index";
import { useAuth } from "@Context/Auth.context";
import { useThemeType } from "@Context/ThemeType.context";
import { mq, rgba } from "@Utils/StyleUtil";
import {
  getAppsBgColor,
  getBodyFont,
  getButtonsColor,
  getCommonSectionBtnBgColor,
  getFooterBgColor,
  getFooterBtnBgColor,
  getFooterBtnTextColor,
  getHomeBgColor,
  getHomeEducationBgColor,
  getLandingSvgimgsColor,
  getLoaderColor,
  getSliderArrowBgcolor,
  getTeacherCardBgcolor,
  getTeacherCardTextcolor,
  getTeacherSliderArrow,
  getUpdateBreadcrumbBgColor,
} from "LocaleBased";
import { SubjectBanner } from "LocaleBased/SubjectBanner";
import { useRouter } from "next/router";
import "rc-menu/assets/index.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-phone-input-2/lib/material.css";
import "react-toastify/dist/ReactToastify.css";
import { createGlobalStyle, css } from "styled-components";
import { normalize } from "styled-normalize";
import { fadeInUp } from "./animations";
import rodalCss from "./rodalCss";
import theme from "./theme";
import toastify from "./toastify";

const InnerGlobalStyle = createGlobalStyle<{
  readonly locale?: string;
  readonly countryCode?: any;
  readonly themeType: string;
}>`
  ${normalize}
  
  *,
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    /* outline: 1px solid red; */
  }

  html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
    font-size: ${({ theme }) => theme.typography.htmlBaseFontPx}px;
    height:-webkit-fill-available ;
  }

  body {
    font-size: 1.6rem;
    overflow-x: hidden;
    line-height: ${({ theme }) => theme.typography.baseLineHeight};
    position: relative;
    cursor: default;
    font-weight: normal;
    font-family: ${({ locale, countryCode, themeType }) => getBodyFont(locale, countryCode, themeType)};
    background-color: ${({ countryCode, themeType }) =>
      countryCode ? getHomeBgColor(countryCode, themeType) : "unset"} !important; 
    color: ${({ theme }) => theme.colors.textColor};
    height: 100% ;
  }

  ${({ countryCode, themeType }) =>
    countryCode === KSA_COUNTRY_CODE &&
    css`
      ${Header} {
        &:not(.is-landing-nav) {
          background-color: ${countryCode
            ? rgba(getHomeBgColor(countryCode as any, themeType), 0.8)
            : "unset"} !important;
        }
      }

      ${BurgerMenu} {
        ${mq(576)} {
          &.cross {
            margin-top: 14px;
          }
        }
      }

      ${DiscountBadge},
      ${PaymentBtn},
      ${CommonButton} {
        background-color: ${countryCode ? getButtonsColor(countryCode as any, themeType) : "unset"} !important;
        color: ${countryCode ? theme.colors.white : "unset"} !important;
      }

      ${ArrowBtn} {
        background-color: ${countryCode ? getSliderArrowBgcolor(countryCode as any, themeType) : "unset"} !important;
      }

      ${ArrowsWrapper} {
        ${CommonButton} {
          background-color: ${countryCode ? getTeacherSliderArrow(countryCode as any, themeType) : "unset"} !important;
        }
      }

      ${ButtonContainer} {
        &:not(.social-button) {
          a,
          button {
            background-color: ${countryCode ? getButtonsColor(countryCode as any, themeType) : "unset"} !important;
            color: ${countryCode ? theme.colors.white : "unset"} !important;
            &.not-fill {
              border: ${countryCode
                ? `1.5px solid ${getButtonsColor(countryCode as any, themeType)}`
                : "unset"} !important;
              background: ${countryCode ? theme.colors.white : "unset"} !important;
              color: ${countryCode ? getButtonsColor(countryCode as any, themeType) : "unset"} !important;
              &:hover {
                border-color: ${countryCode ? getButtonsColor(countryCode as any, themeType) : "unset"} !important;
                background: inherit;
              }
              &[disabled] {
                color: ${({ theme }) => theme.colors.gray4};
                background: inherit;
              }
            }
          }
        }
      }

      ${PageWrapper} {
        background-color: ${countryCode ? getHomeBgColor(countryCode, themeType) : "unset"} !important;
      }

      ${BoxWrapper} {
        .common-section-animate {
          &:nth-child(3) {
            ${CommonButton} {
              background-color: ${countryCode
                ? getCommonSectionBtnBgColor(countryCode as any, themeType)
                : "unset"} !important;
              color: ${countryCode ? getLandingSvgimgsColor(countryCode as any, themeType) : "unset"} !important;
              font-weight: 700;
            }
          }
        }
      }

      ${TeacherCardWrapper} {
        background-color: ${countryCode ? getTeacherCardBgcolor(countryCode as any, themeType) : "unset"} !important;
      }

      ${TeacherName},
      ${TeacherStats} {
        color: ${countryCode ? getTeacherCardTextcolor(countryCode as any, themeType) : "unset"} !important;
      }

      ${Wrapper} {
        background-color: ${countryCode ? getHomeEducationBgColor(countryCode as any, themeType) : "unset"} !important;
      }

      ${CaregoryItem} {
        ${countryCode &&
        themeType === FOUNDING_DAY_THEME &&
        css`
          background-color: ${({ theme }) => theme.colors.KsaFounding_footerColor} !important;
          color: ${({ theme }) => theme.colors.white} !important;
        `}
      }

      ${BreadcrumbsHomeWrapper} {
        background-color: ${countryCode
          ? getUpdateBreadcrumbBgColor(countryCode as any, themeType)
          : "unset"} !important;
      }

      ${DesktopLayout} {
        background-color: ${countryCode ? getHomeBgColor(countryCode as any, themeType) : "unset"} !important;
      }

      ${MethodsWrapper} {
        & + ${ButtonContainer} {
          a {
            background-color: ${countryCode ? getButtonsColor(countryCode as any, themeType) : "unset"} !important;
            color: ${({ theme }) => theme.colors.white};
          }
        }
      }

      ${DownloadAppLink} {
        background: ${countryCode ? getAppsBgColor(countryCode as any, themeType) : "unset"}!important;
      }

      ${SubjectBanner} {
        background: ${countryCode ? getButtonsColor(countryCode as any, themeType) : "unset"}!important;
      }

      ${FooterWrapper} {
        background-color: ${countryCode ? getFooterBgColor(countryCode as any, themeType) : "unset"} !important;
      }

      ${SubjectContentPlaceHolder},
      ${SubjectCardImagePlaceholder},
      ${SubjectCardImagePlaceHolder} {
        background-color: ${countryCode ? getLoaderColor(countryCode as any, themeType) : "unset"} !important;
      }

      ${BaimsAIInner} {
        ${countryCode &&
        themeType === FOUNDING_DAY_THEME &&
        css`
          background-color: ${({ theme }) => theme.colors.white} !important;
          border: 1px solid ${({ theme }) => theme.colors.gray5_old} !important;
        `}
      }

      &.is-landing-footer {
        ${CommonButton} {
          background-color: ${countryCode ? getFooterBtnBgColor(countryCode as any, themeType) : "unset"} !important;
          color: ${countryCode ? getFooterBtnTextColor(countryCode as any, themeType) : "unset"} !important;
          font-weight: ${countryCode ? theme.fontWeight.font700 : "unset"} !important;
          &:hover {
            background-color: ${theme.colors.brown2};
            color: ${theme.colors.brown1};
          }
        }
      }

      ${LogoLink} {
        ${Boolean(countryCode === KSA_COUNTRY_CODE && themeType === NATIONAL_DAY_THEME) &&
        css`
          display: flex;
          align-items: center;
        `}
      }
    `}

  [hidden] {
    display: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }

  a,
  a:hover,
  a:visited,
  a:link,
  a:active {
    text-decoration: none;
    outline: none;
  }

  button,
  button:focus {
    outline: none;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  input:focus,
  select:focus,
  textarea:focus {
    outline: none;
  }
  input:-webkit-autofill { 
    -webkit-background-clip: text;
  }
  .__react_component_tooltip {
    padding: 0 !important;
    opacity: 1 !important;
  }

  .intercom-launcher,
  .intercom-launcher-active{
    bottom:105px !important ;
  }

  .common-section-imgWrapper {
    opacity: 0;
  }
  .common-section-animate .common-section-imgWrapper {
    opacity: 1;
    animation: 1.5s ${fadeInUp} ease-in-out;
  }
  .__react_component_tooltip.show {
    background: transparent;
  }
  video[id^="video_track-cam"] {
    object-fit: contain !important;
    height: 100% !important;
  }

  #livestream-client-holder,
  div[class*="3Sxu7"] {
    position: absolute !important;
    left: 10px !important;
    bottom: 10px !important;
    width: 240px !important;
    height: 140px !important;
    border-radius: 20px;
    overflow: hidden !important;
    & > div {
      min-height: 140px !important;
    }
    video[id^="video_track-video"],
    video[id^="video_track-cam"] {
      height: 140px !important;
      object-fit: cover !important;
      border-radius: 20px;
    }
  
    .agora_video_player,
    div[id^="agora-video-player-track"] {
      background: transparent !important;
      height: 137.5px !important;
      border-radius: 20px !important;
    }
  }

  video[id^="video_track-video"] {
    object-fit: contain !important;
  }
  

  .fullscreen-enabled #livestreaming-container {
    background: green;
  }
  
  .Toastify .Toastify__toast-container--top-right{
    right: unset !important;
    left: 1em !important;
  }
  .Toastify__toast-container--top-right .Toastify__toast{
    flex-direction: row
  }
  .Toastify__toast-container--top-left .Toastify__toast{
    flex-direction: row-reverse 
  }
  ${rodalCss}
  ${toastify}
`;

export const GlobalStyle = () => {
  const { locale } = useRouter();
  const { localIpInfo } = useAuth();
  const { themeType } = useThemeType();
  return <InnerGlobalStyle locale={locale} countryCode={localIpInfo?.country_code} themeType={themeType} />;
};
