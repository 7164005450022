import styled from "styled-components";

import Link from "@Components/common/Link";
import { mq, rgba } from "@Utils/StyleUtil";
import Image from "next/image";
import { css } from "styled-components";

export const SubjectContentBg = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  user-select: none;
  transform: scale(1, 1) /*rtl:scale(-1, 1)*/;
  text-indent: -10000px;
`;
export const SubjectContentPlaceHolder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  background-color: ${({ theme }) => theme.colors.gray4};
  user-select: none;
  transform: scale(1, 1) /*rtl:scale(-1, 1)*/;
  text-indent: -10000px;
`;
export const SubjectInner = styled.div<{ readonly isAlgoliaPackage?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ isAlgoliaPackage }) => (isAlgoliaPackage ? "10px" : "1.6em")};
  line-height: 1.6;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  ${mq(768)} {
    padding: ${({ isAlgoliaPackage }) => (isAlgoliaPackage ? "5px" : "1.6em")};
  }
`;
export const SubjectTopContent = styled.div`
  max-width: calc(75 / 100 * (100% + (1.6em * 2)));
`;
export const SubjectBottomContent = styled.div`
  display: flex;
  align-items: center;
`;
export const Title = styled.h3<{ readonly isAlgoliaPackage?: boolean }>`
  font-size: ${({ isAlgoliaPackage }) => (isAlgoliaPackage ? "9px" : "2em")};
  line-height: 1.6;
  max-height: calc(1.6 * 2em * 1);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 0.2em;
  word-break: break-word;
  font-weight: ${({ isAlgoliaPackage }) => (isAlgoliaPackage ? "500" : "700")};
  ${mq(768)} {
    font-size: ${({ isAlgoliaPackage }) => (isAlgoliaPackage ? "7.5px" : "2em")};
  }
`;
export const Instructor = styled.p<{ readonly isAlgoliaPackage?: boolean }>`
  font-size: ${({ isAlgoliaPackage }) => (isAlgoliaPackage ? "7px" : "1.4em")};
  font-weight: 400;
  line-height: 1.42857143;
  ${mq(768)} {
    font-size: ${({ isAlgoliaPackage }) => (isAlgoliaPackage ? "5px" : "1.4em")};
  }
`;
export const StarIcon = styled.img<{ readonly isAlgoliaPackage?: boolean }>`
  display: block;
  margin-right: ${({ isAlgoliaPackage }) => (isAlgoliaPackage ? "3px" : "0.53em")};
  min-height: ${({ isAlgoliaPackage }) => (isAlgoliaPackage ? "6px" : "1.2666812896728516em")};
  max-height: ${({ isAlgoliaPackage }) => (isAlgoliaPackage ? "6px" : "1.2666812896728516em")};
  min-width: ${({ isAlgoliaPackage }) => (isAlgoliaPackage ? "6px" : " 1.3370030403137207em")};
  max-width: ${({ isAlgoliaPackage }) => (isAlgoliaPackage ? "6px" : "1.3370030403137207em")};
  user-select: none;
`;
export const Info = styled.p<{ readonly isAlgoliaPackage?: boolean }>`
  font-size: ${({ isAlgoliaPackage }) => (isAlgoliaPackage ? "7px" : "1.4em")};
  line-height: 1.42857143;
  ${mq(768)} {
    font-size: ${({ isAlgoliaPackage }) => (isAlgoliaPackage ? "5.5px" : "1.4em")};
  }
`;
export const Ellipse = styled.div<{ isAlgoliaPackage?: boolean }>`
  min-width: ${({ isAlgoliaPackage }) => (isAlgoliaPackage ? "3px" : "0.4em")};
  max-width: ${({ isAlgoliaPackage }) => (isAlgoliaPackage ? "3px" : "0.4em")};
  min-height: ${({ isAlgoliaPackage }) => (isAlgoliaPackage ? "3px" : "0.4em")};
  max-height: ${({ isAlgoliaPackage }) => (isAlgoliaPackage ? "3px" : "0.4em")};
  opacity: 0.4;
  background-color: ${({ theme }) => theme.colors.gray1};
  margin: ${({ isAlgoliaPackage }) => (isAlgoliaPackage ? "0 3px" : "0 0.8em")};
  border-radius: 50%;
  user-select: none;
`;
export const University = styled.p<{ readonly isAlgoliaPackage?: boolean }>`
  margin: auto 0 0.8em;
  font-size: ${({ isAlgoliaPackage }) => (isAlgoliaPackage ? "7px" : "1.4em")};
  line-height: 1.42857143;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(75 / 100 * (100% + (1.6em * 2)));
  ${mq(768)} {
    font-size: ${({ isAlgoliaPackage }) => (isAlgoliaPackage ? "5px" : "1.4em")};
  }
`;
export const Price = styled.p<{ isSlashed?: boolean; isAlgoliaPackage?: boolean }>`
  font-size: ${({ isAlgoliaPackage }) => (isAlgoliaPackage ? "8px" : "1.6em")};
  font-weight: 500;
  line-height: 1.25;
  ${({ isSlashed }) =>
    isSlashed &&
    css`
      text-decoration-line: line-through;
    `}
`;
export const PriceAfterDiscount = styled.p<{ isAlgoliaPackage?: boolean }>`
  font-size: ${({ isAlgoliaPackage }) => (isAlgoliaPackage ? "8px" : "1.6em")};
  line-height: 1.5;
  font-weight: 400;
  background-color: ${({ theme }) => rgba(theme.colors.white, 0.2)};
  backdrop-filter: blur(10px);
  border-radius: 3.4em;
  padding: 0 8px;
`;
export const SubjectContentWrapper = styled(Link)<{ isLightTheme?: boolean; isAlgoliaPackage?: boolean }>`
  width: 100%;
  padding-top: calc(100% / 1.3333);
  position: relative;
  overflow: hidden;
  border-radius: ${({ isAlgoliaPackage }) => (isAlgoliaPackage ? "5px" : "0.7em")};
  cursor: pointer;
  display: block;
  background: ${({ theme }) => theme.colors.gray4};
  color: ${({ theme, isLightTheme }) => (isLightTheme ? theme.colors.white : theme.colors.gray1)};
  box-shadow: 0px 2.93878px 14.6939px rgba(0, 0, 0, 0.1);
  ${({ isLightTheme }) =>
    isLightTheme &&
    css`
      ${Ellipse} {
        background-color: ${({ theme }) => theme.colors.white};
      }
      ${PriceAfterDiscount} {
        background-color: ${({ theme }) => rgba(theme.colors.white, 0.5)};
      }
    `}
  transition: 0.4s ease box-shadow;
  &:hover {
    box-shadow: none;
  }
`;
export const WatchedPercentageWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 1.6em;
  color: #fff;
  line-height: 1.75;
  &.dark {
    color: #1a1a1a;
    font-size: 1.8rem;
    gap: 11px;
    font-weight: 500;
  }
`;

export const WatchedPercentage = styled.div<{ readonly smallCircle?: boolean }>`
  min-width: ${({ smallCircle }) => (smallCircle ? "14px" : "2em")};
  max-width: ${({ smallCircle }) => (smallCircle ? "14px" : "2em")};
  min-height: ${({ smallCircle }) => (smallCircle ? "14px" : "2em")};
  max-height: ${({ smallCircle }) => (smallCircle ? "14px" : "2em")};
  transform: rotate(185deg);
  &:lang(ar) {
    transform: rotate(175deg);
  }
  ${({ smallCircle }) =>
    smallCircle &&
    css`
      svg {
        width: 14px;
        height: 15px;
      }
    `}
  &.dark {
    min-width: 4rem;
    max-width: 4rem;
    min-height: 4rem;
    max-height: 4rem;
  }
  .circle-bg {
    fill: none;
    stroke: ${({ smallCircle, theme }) => (smallCircle ? theme.colors.gray5 : "rgba(255, 255, 255, 0.3)")};
    stroke-width: 4.5;
  }
  .circle-bg.dark {
    stroke: rgba(0, 0, 0, 0.15);
  }

  .circle {
    fill: none;
    stroke-width: 4.5;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
  }

  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }

  .circle {
    stroke: ${({ smallCircle, theme }) => (smallCircle ? theme.colors.gray1 : theme.colors.white)};
  }
  .circle.dark {
    stroke: ${({ theme }) => theme.colors.nero};
  }
`;
