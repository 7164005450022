import { mq } from "@Utils/StyleUtil";
import Image from "next/image";
import ksaNationalDayGreenLogo from "public/images/logos/ksa-national-green-logo.svg";
import ksaNationalDayWhiteLogo from "public/images/logos/ksa-national-white-logo.svg";
import styled from "styled-components";
type Props = {
  white?: Boolean;
};

export const LogoWrapper = styled.div`
  max-width: 56.9px;
  min-width: 56.9px;
  max-height: 52px;
  min-height: 52px;
  ${mq(1024)} {
    display: none;
  }
`;

export default function KsaNationalDayLogo({ white }: Props) {
  return white ? (
    <LogoWrapper>
      <Image src={ksaNationalDayWhiteLogo} />
    </LogoWrapper>
  ) : (
    <LogoWrapper>
      <Image src={ksaNationalDayGreenLogo} />
    </LogoWrapper>
  );
}
