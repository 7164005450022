import { keyframes } from "styled-components";

export const tada = keyframes`
  from {
    transform: scale3d(1, 1, 1);
  }
  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
`;

export const scaleUp = keyframes`
  from {
    transform: scale(0);
  } to {
    transform: scale(1);
  }
`;
export const slideDown = keyframes`
  from {
    transform: translateY(0);
  } to {
    transform: translateY(20px);
  }
`;
export const fadeIn = keyframes`
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
`;
export const rotateSpinner = keyframes`
  from {
    transform: rotate(0deg);
  } to {
    transform: rotate(360deg);
  }
`;
export const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 18%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const fadeInUpDown = keyframes`
  0% {
    transform: translateY(0);
    margin-left: -2px;
    margin-right: 0;
  }
  50% {
    transform: translateY(20px);
    margin-right: -2px;
    margin-left: 0;
  }
  100% {
    transform: translateY(0px);
    margin-left: -2px;
    margin-right: 0;
  }
`;

export const shake = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`;

export const dotFlashing = keyframes`
  0% {
    background-color: #0177c0;
  }
  50%,
  100% {
    background-color: #eee;
  }
`;

export const BluredBg = keyframes`
  0% {
    opacity:0% ;
  }
  100% {
    opacity:100% ;
  }
`;
