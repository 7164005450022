import { CountriesList, FOUNDING_DAY_THEME, KSA_COUNTRY_CODE, NATIONAL_DAY_THEME } from "@Constants/index";
import theme from "@Style/theme";
import { rgba } from "@Utils/StyleUtil";
import DropdownSvgIcon from "public/svgs/icons/global-icons/DropdownSvgIcon";
import LightArrowLeftSvgIcon from "public/svgs/icons/global-icons/LightArrowLeftSvgIcon";
import LightArrowRightSvgIcon from "public/svgs/icons/global-icons/LightArrowRightSvgIcon";
import NavArrowRightSvgIcon from "public/svgs/icons/global-icons/LongArrowRightSvgIcon";

const countrycodes = [...CountriesList].map((x) => x.subroute);
type CountryType = (typeof countrycodes)[number];

export const getBodyFont = (lang: string, countryCode: any, themeType: string) => {
  if (!countryCode) return theme.typography.fontFamily;
  switch (countryCode) {
    case KSA_COUNTRY_CODE:
      switch (themeType) {
        case NATIONAL_DAY_THEME:
          return theme.typography.fontFamily;
        case FOUNDING_DAY_THEME:
          return theme.typography.fontFamily;
        default:
          return theme.typography.fontFamily;
      }
    default:
      return theme.typography.fontFamily;
  }
};

export const getHomeBgColor = (countryCode: any, themeType: string) => {
  if (!countryCode) return theme.colors.white;
  switch (countryCode) {
    case KSA_COUNTRY_CODE:
      switch (themeType) {
        case NATIONAL_DAY_THEME:
          return theme.colors.white;
        case FOUNDING_DAY_THEME:
          return theme.colors.KsaFounding_naveColor;
        default:
          return theme.colors.white;
      }
    default:
      return theme.colors.white;
  }
};

export const getButtonsColor = (countryCode: any, themeType: string) => {
  if (!countryCode) return theme.colors.seagule;
  switch (countryCode) {
    case KSA_COUNTRY_CODE:
      switch (themeType) {
        case NATIONAL_DAY_THEME:
          return theme.colors.KsaNational_primaryColor;
        case FOUNDING_DAY_THEME:
          return theme.colors.KsaFounding_primaryColor;
        default:
          return theme.colors.seagule;
      }
    default:
      return theme.colors.seagule;
  }
};

export const getLoaderColor = (countryCode: any, themeType: string) => {
  if (!countryCode) return theme.colors.gray4;
  switch (countryCode) {
    case KSA_COUNTRY_CODE:
      switch (themeType) {
        case NATIONAL_DAY_THEME:
          return theme.colors.gray4;
        case FOUNDING_DAY_THEME:
          return rgba(theme.colors.gray5, 0.8);
        default:
          return theme.colors.gray4;
      }
    default:
      return theme.colors.gray4;
  }
};

export const getUpdateBreadcrumbBgColor = (countryCode: any, themeType: string) => {
  if (!countryCode) return rgba(theme.colors.gray6, 0.6);
  switch (countryCode) {
    case KSA_COUNTRY_CODE:
      switch (themeType) {
        case NATIONAL_DAY_THEME:
          return rgba(theme.colors.gray6, 0.6);
        case FOUNDING_DAY_THEME:
          return rgba(theme.colors.white, 0.7);
        default:
          return rgba(theme.colors.gray6, 0.6);
      }
    default:
      return rgba(theme.colors.gray6, 0.6);
  }
};

export const getLandingSvgimgsColor = (countryCode: any, themeType: string) => {
  if (!countryCode) theme.colors.darkSeagule;
  switch (countryCode) {
    case KSA_COUNTRY_CODE:
      switch (themeType) {
        case NATIONAL_DAY_THEME:
          return theme.colors.darkSeagule;
        case FOUNDING_DAY_THEME:
          return theme.colors.KsaFounding_primaryColor;
        default:
          return theme.colors.darkSeagule;
      }
    default:
      return theme.colors.darkSeagule;
  }
};

export const getThemeVideo = (countryCode: any, themeType: string) => {
  if (!countryCode) return "/videos/HeroArea/baims.mp4";
  switch (countryCode) {
    case KSA_COUNTRY_CODE:
      switch (themeType) {
        case NATIONAL_DAY_THEME:
          return "/videos/HeroArea/baims-sa.mp4";
        case FOUNDING_DAY_THEME:
          return "/videos/HeroArea/baims-sa2.mp4";
        default:
          return "/videos/HeroArea/baims.mp4";
      }
    default:
      return "/videos/HeroArea/baims.mp4";
  }
};

export const getTeacherCardArrow = (countryCode: any, themeType: string) => {
  if (!countryCode) return <NavArrowRightSvgIcon width="11" height="9" />;
  switch (countryCode) {
    case KSA_COUNTRY_CODE:
      switch (themeType) {
        case NATIONAL_DAY_THEME:
          return <NavArrowRightSvgIcon width="11" height="9" />;
        case FOUNDING_DAY_THEME:
          return <NavArrowRightSvgIcon width="11" height="9" color={theme.colors.nero} />;
        default:
          return <NavArrowRightSvgIcon width="11" height="9" />;
      }
    default:
      return <NavArrowRightSvgIcon width="11" height="9" />;
  }
};

export const getSliderArrow = (countryCode: any, themeType: string) => {
  if (!countryCode) return <LightArrowLeftSvgIcon color={theme.colors.nero} />;
  switch (countryCode) {
    case KSA_COUNTRY_CODE:
      switch (themeType) {
        case NATIONAL_DAY_THEME:
          return <LightArrowLeftSvgIcon color={theme.colors.nero} />;
        case FOUNDING_DAY_THEME:
          return <LightArrowLeftSvgIcon color={theme.colors.white} />;
        default:
          return <LightArrowLeftSvgIcon color={theme.colors.nero} />;
      }
    default:
      return <LightArrowLeftSvgIcon color={theme.colors.nero} />;
  }
};

export const getSliderArrowBgcolor = (countryCode: any, themeType: string) => {
  if (!countryCode) return theme.colors.gray6;
  switch (countryCode) {
    case KSA_COUNTRY_CODE:
      switch (themeType) {
        case NATIONAL_DAY_THEME:
          return theme.colors.gray6;
        case FOUNDING_DAY_THEME:
          return theme.colors.KsaFounding_primaryColor;
        default:
          return theme.colors.gray6;
      }
    default:
      return theme.colors.gray6;
  }
};

export const getTeacherCardBgcolor = (countryCode: any, themeType: string) => {
  if (!countryCode) return theme.colors.darkSeagule;
  switch (countryCode) {
    case KSA_COUNTRY_CODE:
      switch (themeType) {
        case NATIONAL_DAY_THEME:
          return rgba(theme.colors.white, 0.15);
        case FOUNDING_DAY_THEME:
          return rgba(theme.colors.white, 0.6);
        default:
          return theme.colors.darkSeagule;
      }
    default:
      return theme.colors.darkSeagule;
  }
};

export const getTeacherCardTextcolor = (countryCode: any, themeType: string) => {
  if (!countryCode) return theme.colors.white;
  switch (countryCode) {
    case KSA_COUNTRY_CODE:
      switch (themeType) {
        case NATIONAL_DAY_THEME:
          return theme.colors.white;
        case FOUNDING_DAY_THEME:
          return theme.colors.nero;
        default:
          return theme.colors.white;
      }
    default:
      return theme.colors.white;
  }
};

export const getTeacherSliderArrow = (countryCode: any, themeType: string) => {
  if (!countryCode) return theme.colors.darkSeagule;
  switch (countryCode) {
    case KSA_COUNTRY_CODE:
      switch (themeType) {
        case NATIONAL_DAY_THEME:
          return theme.colors.KsaNational_naveColor;
        case FOUNDING_DAY_THEME:
          return theme.colors.KsaFounding_primaryColor;
        default:
          return theme.colors.darkSeagule;
      }
    default:
      return theme.colors.darkSeagule;
  }
};

export const getUpdateBreadCrumbArrowColor = (countryCode: any, themeType: string) => {
  if (!countryCode) return <DropdownSvgIcon margin="0 12px" />;
  switch (countryCode) {
    case KSA_COUNTRY_CODE:
      switch (themeType) {
        case NATIONAL_DAY_THEME:
          return <DropdownSvgIcon margin="0 12px" />;
        case FOUNDING_DAY_THEME:
          return <DropdownSvgIcon color={theme.colors.KsaFounding_primaryColor} margin="0 12px" />;
        default:
          return <DropdownSvgIcon margin="0 12px" />;
      }
    default:
      return <DropdownSvgIcon margin="0 12px" />;
  }
};

export const getLandingSvgIconsColor = (countryCode: any, themeType: string) => {
  if (!countryCode) return theme.colors.seagule;
  switch (countryCode) {
    case KSA_COUNTRY_CODE:
      switch (themeType) {
        case NATIONAL_DAY_THEME:
          return theme.colors.seagule;
        case FOUNDING_DAY_THEME:
          return theme.colors.KsaFounding_redColor;
        default:
          return theme.colors.seagule;
      }
    default:
      return theme.colors.seagule;
  }
};

export const AppsIconBgColor = (isFoundingDay: boolean) => {
  if (isFoundingDay) {
    return theme.colors.KsaFounding_primaryColor;
  } else {
    return theme.colors.black;
  }
};

export const getFooterBgColor = (countryCode: any, themeType: string) => {
  if (!countryCode) return theme.colors.darkSeagule;
  switch (countryCode) {
    case KSA_COUNTRY_CODE:
      switch (themeType) {
        case NATIONAL_DAY_THEME:
          return theme.colors.KsaNational_naveColor;
        case FOUNDING_DAY_THEME:
          return theme.colors.KsaFounding_footerColor;
        default:
          return theme.colors.darkSeagule;
      }
    default:
      return theme.colors.darkSeagule;
  }
};

export const getAppsBgColor = (countryCode: any, themeType: string) => {
  if (!countryCode) return theme.colors.black;
  switch (countryCode) {
    case KSA_COUNTRY_CODE:
      switch (themeType) {
        case NATIONAL_DAY_THEME:
          return theme.colors.black;
        case FOUNDING_DAY_THEME:
          return theme.colors.KsaFounding_primaryColor;
        default:
          return theme.colors.black;
      }
    default:
      return theme.colors.black;
  }
};

export const getHomeEducationBgColor = (countryCode: any, themeType: string) => {
  if (!countryCode) return theme.colors.gray7;
  switch (countryCode) {
    case KSA_COUNTRY_CODE:
      switch (themeType) {
        case NATIONAL_DAY_THEME:
          return theme.colors.gray7;
        case FOUNDING_DAY_THEME:
          return theme.colors.white;
        default:
          return theme.colors.gray7;
      }
    default:
      return theme.colors.gray7;
  }
};

export const getFooterBtnBgColor = (countryCode: any, themeType: string) => {
  if (!countryCode) return theme.colors.seagule;
  switch (countryCode) {
    case KSA_COUNTRY_CODE:
      switch (themeType) {
        case NATIONAL_DAY_THEME:
          return theme.colors.KsaNational_primaryColor;
        case FOUNDING_DAY_THEME:
          return theme.colors.KsaFounding_naveColor;
        default:
          return theme.colors.seagule;
      }
    default:
      return theme.colors.seagule;
  }
};

export const getFooterBtnTextColor = (countryCode: any, themeType: string) => {
  if (!countryCode) return theme.colors.white;
  switch (countryCode) {
    case KSA_COUNTRY_CODE:
      switch (themeType) {
        case NATIONAL_DAY_THEME:
          return theme.colors.white;
        case FOUNDING_DAY_THEME:
          return theme.colors.KsaFounding_primaryColor;
        default:
          return theme.colors.white;
      }
    default:
      return theme.colors.white;
  }
};

export const getSearchBarColor = (countryCode: any, themeType: string, path: string) => {
  if (!countryCode) return rgba(theme.colors.gray6, 0.6);
  switch (countryCode) {
    case KSA_COUNTRY_CODE:
      switch (themeType) {
        case NATIONAL_DAY_THEME:
          return rgba(theme.colors.gray6, 0.6);
        case FOUNDING_DAY_THEME:
          switch (path) {
            case "/":
              return rgba(theme.colors.gray6, 0.6);
            default:
              return rgba(theme.colors.white, 0.7);
          }
        default:
          return rgba(theme.colors.gray6, 0.6);
      }
    default:
      return rgba(theme.colors.gray6, 0.6);
  }
};

export const getCommonSectionBgColor = (countryCode: any, themeType: string) => {
  if (!countryCode) return "darkSeagule";
  switch (countryCode) {
    case KSA_COUNTRY_CODE:
      switch (themeType) {
        case NATIONAL_DAY_THEME:
          return "KsaNational_naveColor";
        case FOUNDING_DAY_THEME:
          return "KsaFounding_naveColor";
        default:
          return "darkSeagule";
      }
    default:
      return "darkSeagule";
  }
};

export const getCommonSectionBtnBgColor = (countryCode: any, themeType: string) => {
  if (!countryCode) return theme.colors.gray6;
  switch (countryCode) {
    case KSA_COUNTRY_CODE:
      switch (themeType) {
        case NATIONAL_DAY_THEME:
          return theme.colors.gray6;
        case FOUNDING_DAY_THEME:
          return theme.colors.KsaFounding_naveColor;
        default:
          return theme.colors.gray6;
      }
    default:
      return theme.colors.gray6;
  }
};

export const getShowAllArrow = (countryCode: any, themeType: string) => {
  if (!countryCode) return <LightArrowRightSvgIcon width="6" height="9" />;
  switch (countryCode) {
    case KSA_COUNTRY_CODE:
      switch (themeType) {
        case NATIONAL_DAY_THEME:
          return <LightArrowRightSvgIcon width="6" height="9" />;
        case FOUNDING_DAY_THEME:
          return <LightArrowRightSvgIcon width="6" height="9" />;
        default:
          return <LightArrowRightSvgIcon width="6" height="9" />;
      }
    default:
      return <LightArrowRightSvgIcon width="6" height="9" />;
  }
};

export const getCommonSectionBtnArrowColor = (isFoundingDay: boolean, arrowURL: boolean) => {
  if (isFoundingDay) {
    if (arrowURL) {
      return theme.colors.KsaFounding_primaryColor;
    } else {
      return theme.colors.white;
    }
  } else {
    if (arrowURL) {
      return theme.colors.darkSeagule;
    } else {
      return theme.colors.white;
    }
  }
};
