import { CommonButton } from "@Components/common/CommonButton";
import Link from "@Components/common/Link";
import { mq, mw, rgba, stripButton } from "@Utils/StyleUtil";
import Image from "next/image";
import styled, { css } from "styled-components";
import { LandingBGColors } from "types/common";

export const OuterWrapper = styled.div<{ readonly commonBG?: LandingBGColors }>`
  min-height: fit-content;
  display: flex;
  align-items: flex-end;
  padding: 80px 0 60px 0;
  transition: 0.5s ease background-color;
`;

export const Wrapper = styled.section`
  width: 100%;
`;

export const SectionTitle = styled.h2<{
  readonly isFoundingDay?: boolean;
}>`
  font-size: 2.8rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 32px;
  position: relative;
  line-height: 40px;
  ${mw(1920)} {
    font-size: calc(2.8rem * 1.4);
  }
  /* display: inline-block; */
  .underlined {
    ${({ isFoundingDay }) =>
      isFoundingDay
        ? css`
            background: url("/images/landing-common-sections/ksaFounding/brown_underline.svg") no-repeat;
          `
        : css`
            background: url("/images/landing-common-sections/yellow_underline.svg") no-repeat;
          `}
    background-position: center bottom;
    background-size: 100% auto;
    padding-bottom: 4px;
    white-space: nowrap;
    &:lang(ar) {
      padding-bottom: 28px;
    }
  }
  .heart {
    position: relative;
    &::after {
      position: absolute;
      ${({ isFoundingDay }) =>
        isFoundingDay
          ? css`
              content: url("/images/landing-common-sections/ksaFounding/brown_heart.svg");
            `
          : css`
              content: url("/images/landing-common-sections/heart.svg");
            `}
      right: calc(-100% + 12px);
    }
    &:lang(ar) {
      &::after {
        left: unset;
        right: calc(-100% + 230px);
      }
    }
  }
  /* &::after {
    position: absolute;
    content: url("/images/landing-common-sections/heart.svg");
    top: 50%;
    transform: translateY(-50%);
  } */
  ${mq(992)} {
    font-size: 2.8rem;
    margin-bottom: 35px;
  }
  ${mq(768)} {
    font-size: 2.4rem;
    margin-bottom: 30px;
  }
  ${mq(576)} {
    font-size: 2rem;
    margin-bottom: 23px;
    .heart {
      &::after {
        right: -100%;
      }

      &:lang(ar) {
        &::after {
          right: calc(-100% + 166px);
          left: unset;
        }
      }
    }
  }
`;
export const SectionDesc = styled.p`
  text-align: center;
  font-size: 2rem;
  line-height: 1.6;
  max-width: 928px;
  margin: 0 auto 60px;
  ${mw(1920)} {
    font-size: calc(1.8rem * 1.4);
  }
  ${mq(992)} {
    font-size: 1.8rem;
    margin: 0 auto 40px;
  }
  ${mq(768)} {
    font-size: 1.6rem;
    margin: 0 auto 32px;
  }
  ${mq(576)} {
    margin-left: 12px;
    margin-right: 12px;
  }
`;
export const TeacherImg = styled(Image)`
  display: block;
  min-width: 52px;
  max-width: 52px;
  height: 52px;
  object-fit: contain;
  object-position: center bottom;
  user-select: none;
  background: ${({ theme }) => theme.colors.seagullNeutrals};
  border-radius: 50%;
  ${mw(1920)} {
    min-width: calc(52px * 1.4);
    max-width: calc(52px * 1.4);
    height: calc(52px * 1.4);
  }
`;

export const TeacherImgPlaceholder = styled.div`
  display: block;
  min-width: 52px;
  max-width: 52px;
  height: 52px;
  object-fit: contain;
  object-position: center bottom;
  user-select: none;
  background: ${({ theme }) => theme.colors.seagullNeutrals};
  border-radius: 50%;
  ${mw(1920)} {
    min-width: calc(52px * 1.4);
    max-width: calc(52px * 1.4);
    height: calc(52px * 1.4);
  }
`;
export const TeacherInfo = styled.div`
  overflow: hidden;
  margin-right: auto;
  margin-left: 12px;
`;
export const TeacherName = styled.h3`
  font-size: 1.6rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 4px;
  line-height: 1.5;
  ${mw(1920)} {
    font-size: calc(1.6rem * 1.4);
  }
`;
export const StarRateIcon = styled.img`
  width: 17.35px;
  height: 16.58px;
  object-fit: contain;
  ${mq(992)} {
    height: 12.66px;
    width: 13.37px;
  }
  ${mq(768)} {
    height: 11.08px;
    width: 11.69px;
  }
`;
export const TeacherStats = styled.div`
  display: flex;
  align-items: center;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.white};
`;
export const TeacherCTAIcon = styled.img`
  width: 10.67px;
  height: 8.8px;
  object-fit: contain;
`;
export const TeacherCardCTA = styled.button`
  ${stripButton};
  transform: scale(1, 1) /*rtl: scale(-1, 1) */;
`;
export const TeachersExploreBtn = styled(CommonButton)`
  width: 100%;
  gap: 12px;
  min-width: 256px;
  margin-top: 28px;
  font-size: 1.6rem;
  padding: 12px;
  font-weight: 500;
  .arrow-icon {
    transform: none /*rtl:scale(-1, 1) */;
  }
  ${mq(992)} {
    min-width: 232px;
    margin-top: 8px;
  }
  ${mq(768)} {
    min-width: 212px;
    margin-top: 32px;
  }
  ${mq(576)} {
    margin-top: 4px;
  }
`;
export const TeacherCardWrapper = styled(Link)`
  display: flex;
  align-items: center;
  width: 296px;
  height: 76px;
  background: ${({ theme }) => theme.colors.darkSeagule};
  border-radius: 10px;
  user-select: none;
  position: relative;
  overflow: hidden;
  padding: 12px 18.67px 12px 12px;
  ${mw(1920)} {
    width: calc(296px * 1.4);
    height: calc(76px * 1.4);
  }
  /* ${mq(992)} {
    width: 336px;
    height: 400px;
    padding: 23px 18px 0 18px;
  }
  ${mq(768)} {
    width: 242px;
    height: 288px;
    padding: 16px 12px 0 12px;
  } */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => rgba(theme.colors.white, 0.15)};
    transition: 0.3s ease background;
  }
  &:hover {
    &::before {
      background: ${({ theme }) => rgba(theme.colors.white, 0.25)};
    }
  }
`;
export const StarsWrapper = styled.div`
  display: flex;
  gap: 4.63px;
  align-items: center;
  margin-right: 9.33px;
  gap: 5.11px;
  transform: translateY(-1px);
  ${mq(992)} {
    gap: 4.63px;
    .teacher-star-icon img {
      width: 2px;
    }
  }
  ${mq(768)} {
    gap: 4px;
  }
  .MuiRating-iconEmpty {
    color: #ababab;
  }
  .rating-stars {
    font-size: 24px;
    color: #fec76c;
    ${mw(1920)} {
      font-size: calc(24px * 1.4);
    }
  }
  .rating-stars:lang(ar) {
    direction: rtl;
    transform: scale(-1, 1);
  }
`;
export const ExploreBtnIcon = styled.img`
  transform: none /*rtl:scale(-1, 1) */;
  height: 11px;
  width: 13.33px;
  object-fit: contain;
`;
export const InstructorCardSpacer = styled.div`
  margin-right: 32px;
  ${mq(576)} {
    margin-right: 28px;
  }
`;
export const MarqueeSpacer = styled.div`
  margin-bottom: 32px;
  ${mq(576)} {
    margin-bottom: 28px;
  }
`;
