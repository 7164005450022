import { DefaultTheme } from "styled-components";

declare module "styled-components" {
  export interface DefaultTheme {
    typography: {
      fontFamily: string;
      fontFamilyAr: string;
      htmlBaseFontPx: number;
      baseLineHeight: number;
    };
    // Colors
    colors: {
      black: string;
      seagule: string;
      seagule_hover: string;
      seagule_active: string;
      seaguleDarker: string;
      seagullNeutrals: string;
      seagull10: string;
      brandColor: string;
      brandColorDimmed: string;
      mountainMeadows: string;
      casablanca: string;
      eastSide: string;
      cinderella: string;
      nero: string;
      white: string;
      textColor: string;
      green: string;
      gray1: string;
      gray2: string;
      gray3: string;
      gray4: string;
      gray5: string;
      gray5_old: string;
      gray6: string;
      gray7: string;
      gray8: string;
      gray9: string;
      brown1: string;
      brown2: string;
      brown3: string;
      red: string;
      lightRed: string;
      darkSeagule: string;
      KsaFounding_primaryColor: string;
      KsaFounding_naveColor: string;
      KsaFounding_yellowColor: string;
      KsaFounding_redColor: string;
      KsaFounding_footerColor: string;
      KsaNational_primaryColor: string;
      KsaNational_naveColor: string;
      mediumSeagule: string;
      purple: string;
      yellow: string;
      body: {
        background: string;
        contrast: string;
        divider: string;
      };
      navbar: {
        shadow: string;
        divider: string;
        searchInputBg: string;
        placeholderColor: string;
      };
      checkbox: {
        color: string;
        hover: string;
      };
      social: {
        google: string;
        twitter: string;
        apple: string;
        appleText: string;
        phone: string;
        padding: string;
      };
      status: {
        error: string;
        success: string;
        warning: string;
      };
      login: {
        bg: string;
        shadow: string;
        borderColor: string;
      };
      user: {
        shadow: string;
      };
    };
    fontsizes: {
      h1: string;
      h2: string;
      h3: string;
      h4: string;
      h5: string;
      h6: string;
      body18: string;
      body16: string;
      body14: string;
      body12: string;
    };
    buttons: {
      borderRadius: string;
      fontSize: string;
      padding: string;
    };
    fontWeight: {
      font300: number;
      font400: number;
      font500: number;
      font600: number;
      font700: number;
      font800: number;
    };
    fonts: {
      weights: { light: number; regular: number; medium: number; demi: number; bold: number };
    };
    radioButton: {
      borderColor: string;
      background: string;
      borderColorChecked: string;
      backgroundChecked: string;
    };
  }
}

const theme: DefaultTheme = {
  // Typography
  typography: {
    fontFamily: "AvenirNextWorld, sans-serif",
    fontFamilyAr: "AvenirNextWorld, sans-serif",
    htmlBaseFontPx: 10,
    baseLineHeight: 1.5,
  },
  // Colors
  colors: {
    black: "#000",
    seagule: "#45AAF2",
    seagule_hover: "#43A5EB",
    seagule_active: "#409ee1",
    seaguleDarker: "#259BF0",
    seagullNeutrals: "#E3F2FD",
    seagull10: "#ECF7FE",
    brandColor: "#00CECB",
    brandColorDimmed: "#262626",
    mountainMeadows: "#13AF72",
    casablanca: "#F6C14E",
    eastSide: "#AE8CCC",
    cinderella: "#FBD5D2",
    nero: "#1A1A1A",
    white: "#fff",
    textColor: "#262626",
    green: "#13AF72",
    gray1: "#454545",
    gray2: "#8E8E8E",
    gray3: "#ABABAB",
    gray4: "#D4D4D4",
    gray5: "#E1E1E1",
    gray5_old: "#EFEFEF",
    gray6: "#F5F5F5",
    gray7: "#FAFAFA",
    gray8: "#C4C4C4",
    gray9: "#f3f3f3",
    brown1: "#735245",
    brown2: "#FFF5E5",
    brown3: "#B67A50",
    red: "#F25151",
    lightRed: "#feeeee",
    darkSeagule: "#023447",
    KsaFounding_primaryColor: "#B67A50",
    KsaFounding_naveColor: "#FFF5E5",
    KsaFounding_yellowColor: "#F4DA6E",
    KsaFounding_redColor: "#E76A58",
    KsaFounding_footerColor: "#B0A383",
    KsaNational_primaryColor: "#4da454",
    KsaNational_naveColor: "#006847",
    mediumSeagule: "#37657E",
    purple: "#6674FF",
    yellow: "#FEC76C",
    body: {
      background: "#fff",
      contrast: "#fafafa",
      divider: "rgba(0, 0, 0, 0.07)",
    },
    navbar: {
      shadow: "rgba(0, 23, 59, 0.1)",
      divider: "#eee",
      searchInputBg: "#eee",
      placeholderColor: "#606977",
    },
    checkbox: {
      color: "#CCC",
      hover: "#AAA",
    },
    social: {
      google: "#EA4335",
      twitter: "#1da1f2",
      apple: "#1A1A1A",
      appleText: "#fff",
      phone: "#13AF72",
      padding: "17px 21px",
    },
    status: {
      error: "#dc3545",
      success: "#28a745",
      warning: "#ffc107",
    },
    login: {
      bg: "rgb(115 190 232 / 60%)",
      shadow: "0px 4px 27px rgb(0 0 0 / 4%)",
      borderColor: "rgba(26,26,26,0.05)",
    },
    user: {
      shadow: "rrrrrrpx",
    },
  },
  fontsizes: {
    h1: "68px",
    h2: "48px",
    h3: "32px",
    h4: "28px",
    h5: "24px",
    h6: "20px",
    body18: "18px",
    body16: "16px",
    body14: "14px",
    body12: "12px",
  },
  buttons: {
    borderRadius: "10px",
    fontSize: "16px",
    padding: "18px 45px",
  },
  fontWeight: {
    font300: 300,
    font400: 400,
    font500: 500,
    font600: 600,
    font700: 700,
    font800: 800,
  },
  fonts: {
    weights: { light: 300, regular: 400, medium: 500, demi: 600, bold: 700 },
  },
  radioButton: {
    borderColor: "#C4C4C4",
    background: "transparent",
    borderColorChecked: "#45B1E7",
    backgroundChecked: "#45B1E7",
  },
};

export default theme;
