// Packages
import { queryString } from "@Utils/index";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { ReactNode } from "react";
import { UrlObject } from "url";

type Props = {
  onClick?: (event: React.MouseEvent<Element, MouseEvent>) => void;
  onMouseEnter?: (event: React.MouseEvent<Element, MouseEvent>) => void;
  children: ReactNode;
  className?: string;
  href?: string | UrlObject | null;
  locale?: string;
  target?: string;
};

const Link = ({ onClick, onMouseEnter, children, className, href, locale, ...props }: Props) => {
  const router = useRouter();
  const { query } = router;

  return href ? (
    <NextLink {...props} href={`${href}?${queryString(query)}`} locale={locale} passHref>
      <a {...props} onClick={onClick} className={className} onMouseEnter={onMouseEnter}>
        {children}
      </a>
    </NextLink>
  ) : (
    <a {...props} onClick={onClick} className={className}>
      {children}
    </a>
  );
};

export default Link;
