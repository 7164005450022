import { REGULAR_THEME } from "@Constants/index";
import { createModel } from "@rematch/core";
import { RootModel } from ".";

// Different theme varints: REGULAR_THEME | NATIONAL_DAY_THEME | FOUNDING_DAY_THEME;
const themeType = createModel<RootModel>()({
  state: {
    themeType: REGULAR_THEME,
  },
});

export default themeType;
