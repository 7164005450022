import { CommonButton } from "@Components/common/CommonButton";
import { mq } from "@Utils/StyleUtil";
import styled from "styled-components";

export const BaimsAIWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: -8px;
`;
export const BaimsAIInner = styled.div`
  background: #f4fafe;
  border: 1px solid #daeefc;
  border-radius: 8px;
  padding: 12px 12px 12px 16px;
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
  ${mq(1024)} {
    margin-left: 0;
  }
  ${mq(895)} {
    padding: 8px 16px;
  }
  ${mq(768)} {
    & > .wrapper {
      margin-right: 44px;
    }
  }
`;
export const BaimsAITitle = styled.h3`
  font-size: ${({ theme }) => theme.fontsizes.body18};
  color: ${({ theme }) => theme.colors.nero};
  line-height: 1.5;
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  span {
    display: none;
  }
  ${mq(895)} {
    font-size: ${({ theme }) => theme.fontsizes.body16};
    font-weight: ${({ theme }) => theme.fontWeight.font500};
    display: inline;
    span {
      display: inline;
    }
  }
`;
export const BaimsAIDesc = styled.p`
  font-size: ${({ theme }) => theme.fontsizes.body16};
  color: ${({ theme }) => theme.colors.nero};
  line-height: 1.75;
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  ${mq(895)} {
    display: inline;
    font-weight: ${({ theme }) => theme.fontWeight.font500};
  }
`;

export const BaimsAICTA = styled(CommonButton)`
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: ${({ theme }) => theme.fontsizes.body18};
  line-height: 1.5;
  font-weight: ${({ theme }) => theme.fontWeight.font600};
  padding: 12px 18px;
  img {
    margin-left: 12px;
  }
  ${mq(895)} {
    background-color: transparent !important;
    box-shadow: none !important;
    padding: 0;
    img {
      margin: 0;
    }
    span {
      display: none;
    }
  }
`;
