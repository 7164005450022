import { grid } from "@Style/grid";
import { mq } from "@Utils/StyleUtil";
import rodal from "rodal";
import styled, { css } from "styled-components";

export const PaymentTitle = styled.h4`
  font-weight: ${({ theme }) => theme.fontWeight.font600};
  font-size: ${({ theme }) => theme.fontsizes.h5};
  line-height: 40px;
  color: ${({ theme }) => theme.colors.nero};
  margin-bottom: 20px;
`;

export const MethodsWrapper = styled.div`
  margin-bottom: 24px;
`;

export const MethodsText = styled.span<{
  readonly line?: boolean;
  readonly isAr?: boolean;
}>`
  font-weight: ${({ line }) => (line ? "500" : "400")};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.nero};
  text-decoration: ${({ line }) => (line ? "underline" : "none")};
  position: relative;
  & .tamara-product-widget {
    padding: 0 !important;
    display: flex;
    gap: 6px;
    line-height: 30px !important;
    @media only screen and (max-width: ${grid.breakpoints.lg}px) {
      display: block !important;
    }
    > span {
      &:last-child {
        position: relative;
        ${({ isAr }) =>
          isAr
            ? css`
                margin-left: 75px;
              `
            : css`
                margin-left: 108px;
              `};
      }
      &:last-child::before {
        content: "";
        height: 30px;
        top: 2px;
        right: 0px;
        position: absolute;
        ${({ isAr }) =>
          isAr
            ? css`
                width: 80px;
                margin-right: 58px;
                background: transparent url("/images/logos/tamara-logo-ar.svg") no-repeat;
              `
            : css`
                width: 100px;
                margin-right: 80px;
                background: transparent url("/images/logos/tamara-logo-en.svg") no-repeat;
              `}
      }
    }
    & .tamara-logo {
      display: none !important;
      position: initial !important;
    }
  }
`;

export const TamaraImg = styled.img`
  margin: 0px 12px;
  width: 100px;
`;

export const VatContainer = styled.div``;

export const TamaraInfoPopup = styled(rodal)`
  backdrop-filter: blur(10px);
  .rodal-mask {
    background: rgba(0, 0, 0, 0.28);
  }
  .rodal-close {
    display: none;
  }
  .rodal-dialog {
    min-width: 464px;
    min-height: 650px;
    border-radius: 20px;
    ${mq(768)} {
      min-width: 100%;
      min-height: 100vh;
      border-radius: 0px;
    }
  }
`;

export const TamaraInfoContainer = styled.div`
  overflow-y: auto;
  padding: 0px 24px;
  margin-right: 4px;
  height: 50vh;
  &::-webkit-scrollbar {
    display: block;
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    margin-bottom: 20px;
    margin-top: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray5};
    border-radius: 9px;
  }
  ${mq(768)} {
    height: 78vh;
  }
`;

export const FormContainer = styled.form``;

export const SubmitButtonContainer = styled.div`
  padding: 24px;
  margin-top: auto;
  position: sticky;
  left: 0;
  bottom: 0;
  background-color: white;
  z-index: 5555;
`;
