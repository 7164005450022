import { ButtonContainer } from "@Components/common/Button";
import { CommonButton } from "@Components/common/CommonButton";
import Link from "@Components/common/Link";
import { SLIDER_ISBEGINNING } from "@Constants/index";
import { BluredBg, scaleUp } from "@Style/animations";
import { mq, rgba, stripButton } from "@Utils/StyleUtil";
import Image from "next/image";
import Rodal from "rodal";
import styled, { css } from "styled-components";

export const ImagesPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.gray5_old};
  position: absolute;
`;

export const InstructorName = styled.p`
  color: ${({ theme }) => theme.colors.gray2};
  font-size: ${({ theme }) => theme.fontsizes.body14};
  margin-bottom: 10px;
`;

// SKELETON RESPONSIVE

export const DeskTopSkelton = styled.div`
  .isDesktop {
    display: block;
    &:lang(ar) {
      transform: scale(-1, 1);
    }
  }
  .istablet,
  .isMobile {
    display: none;
  }
  @media (max-width: 1024px) and (min-width: 767px) {
    .isDesktop,
    .isMobile {
      display: none;
    }
    .istablet {
      display: block;
      &:lang(ar) {
        transform: scale(-1, 1);
      }
    }
  }
  @media (max-width: 767px) {
    .isDesktop,
    .istablet {
      display: none;
    }
    .isMobile {
      display: block;
      &:lang(ar) {
        transform: scale(-1, 1);
      }
    }
    .isMobiles {
      aspect-ratio: 4/3;
    }
  }
`;

export const SubjectPageContainer = styled.div<{ readonly isCoursePage?: boolean }>`
  margin: auto;
  max-width: ${({ isCoursePage }) => (isCoursePage ? "1700px" : "1200px")};
  width: 100%;
  ${mq(1920)} {
    max-width: ${({ isCoursePage }) => (isCoursePage ? "82vw" : "1200px")};
  }
  ${mq(1600)} {
    max-width: ${({ isCoursePage }) => (isCoursePage ? "84vw" : "1200px")};
  }
  ${mq(1366)} {
    max-width: ${({ isCoursePage }) => (isCoursePage ? "90vw" : "1200px")};
  }
  background-color: ${({ theme, isCoursePage }) => (isCoursePage ? theme.colors.white : "")};
  ${mq(1024)} {
    max-width: 100%;
    min-width: 100%;
    ${({ theme, isCoursePage }) =>
      isCoursePage &&
      css`
        background-color: ${theme.colors.gray7};
      `}
  }
  ${mq(768)} {
  }
  .hidden {
    display: none;
  }
`;

// DESKTOP LAYOUT
export const DesktopLayout = styled.div<{ readonly isCoursePage?: boolean }>`
  display: flex;
  justify-content: space-between;
  gap: 28px;
  ${mq(1024)} {
    display: none;
  }
`;

export const SubjectInformation = styled.div<{ readonly isCoursePage?: boolean }>`
  flex: ${({ isCoursePage }) => (isCoursePage ? "0.7" : "0.55")};
  padding: ${({ isCoursePage }) => (isCoursePage ? "32px 0px 44.5px 40px" : "32px 0px 25px 40px")};
  &:lang(ar) {
    padding: 32px 0px 25px 45px;
  }
  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  ${mq(1024)} {
    display: block;
    padding: ${({ isCoursePage }) => (isCoursePage ? "0 0 10px 0" : "10px 0")};
    &:lang(ar) {
      padding: ${({ isCoursePage }) => (isCoursePage ? "0 0 10px 0" : "10px 0")};
    }
  }
`;

export const SubjectFixedCards = styled.div<{
  readonly isCoursePage?: boolean;
  readonly isBannerVisible?: boolean;
}>`
  flex: ${({ isCoursePage }) => (isCoursePage ? "0.3" : "0.45")};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 36px 28px 0px;
  height: ${({ isBannerVisible }) => (isBannerVisible ? "calc(100vh - 160px)" : "calc(100vh - 110px)")};
  position: sticky;
  top: ${({ isBannerVisible }) => (isBannerVisible ? "160px" : "110px")}; // Navbar Height
  left: 0%;
`;

export const GeneralRateNumber = styled.div`
  .active_icon {
    font-size: ${({ theme }) => theme.fontsizes.h5};
    color: ${({ theme }) => theme.colors.yellow};
    margin-right: 33px;
  }
  .deactive_icon {
    font-size: ${({ theme }) => theme.fontsizes.h5};
    color: ${({ theme }) => theme.colors.gray5};
    margin-right: 33px;
  }
`;
export const CardSkeltonStyle = styled.div`
  height: calc(100vh - 109px);
  overflow: hidden;
`;

// TABLET AND MOBILE LAYOUT
export const TabletLayout = styled.div`
  display: none;
  ${mq(1024)} {
    display: block;
  }
`;

export const SubscribedSubject = styled.div``;

export const NotSubscribedSubject = styled.div`
  padding-bottom: 100px;
`;

export const TabsContainer = styled.div<{
  readonly isLight?: boolean;
  readonly color?: string;
  readonly isBannerVisible?: boolean;
}>`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0px 32px;
  position: sticky;
  top: ${({ isBannerVisible }) => (isBannerVisible ? "140px" : "0%")};
  left: 0%;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.07);
  z-index: 98;
  .active {
    font-weight: ${({ theme }) => theme.fontWeight.font600};
    font-size: ${({ theme }) => theme.fontsizes.body16};
    line-height: 28px;
    color: ${({ isLight, color, theme }) => (isLight ? color : theme.colors.nero)};
    border-bottom: 2px solid ${({ isLight, color, theme }) => (isLight ? color : theme.colors.nero)};
  }
  ${mq(768)} {
    padding: 0px 24px;
  }
`;

export const TabsWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const Tab = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  line-height: 28px;
  flex: 1;
  padding: 12px 0px 8px;
  text-align: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.gray2};
`;

export const CoursesTabContainer = styled.div<{
  readonly tabMinHeight?: number;
  readonly subjectInfoHeight?: number;
}>`
  min-height: calc(100vh - 135px);
  ${mq(768)} {
    min-height: ${({ tabMinHeight, subjectInfoHeight }) =>
      tabMinHeight && `${tabMinHeight - (subjectInfoHeight + 72)}px`};
  }
`;

export const InfoTabContainer = styled.div<{
  readonly tabMinHeight?: number;
  readonly subjectInfoHeight?: number;
}>`
  margin-top: 28px;
  min-height: calc(100vh - 163px);
  ${mq(768)} {
    min-height: ${({ tabMinHeight, subjectInfoHeight }) =>
      tabMinHeight && `${tabMinHeight - (subjectInfoHeight + 100)}px`};
  }
`;

export const RateTabContainer = styled(InfoTabContainer)``;

export const SimilarTabContainer = styled(InfoTabContainer)``;

// HEADER
export const SubjectsHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 36px;
  position: relative;
  ${mq(1024)} {
    gap: 24px;
    padding: 32px;
    margin-bottom: 0px;
  }
  ${mq(768)} {
    padding: 0px;
  }
`;

export const SubjectImageContainer = styled.div`
  position: relative;
  min-width: 180px;
  aspect-ratio: 4 / 3;
  border-radius: 5.902px;
  overflow: hidden;
  ${mq(1024)} {
    min-width: 264px;
    border-radius: 8px;
  }
  ${mq(768)} {
    min-width: 100%;
    border-radius: 0px;
    &:lang(ar) {
      transform: scale(-1, 1);
    }
  }
`;

export const SubjectImage = styled(Image)``;

export const MobileLabel = styled.div`
  position: absolute;
  bottom: 16px;
  left: 24px;
`;

export const Div = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const SubjectInfoWrapper = styled.div<{ readonly isSubscribed?: boolean }>`
  display: none;
  padding: ${({ isSubscribed }) => (isSubscribed ? "16px 0px 18px" : "16px 24px 18px")};
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  ${mq(768)} {
    display: flex;
  }
`;

export const HeaderTopContent = styled.div``;

export const HeaderLabelsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export const SubscribedByPackage = styled.p<{
  readonly color?: string;
}>`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-weight: ${({ theme }) => theme.fontWeight.font500};
  font-size: ${({ theme }) => theme.fontsizes.body14};
  line-height: 28px;
  color: ${({ color }) => color};
  cursor: pointer;
  padding: 2px 12px;
  border-radius: 20px;
  background-color: ${({ color }) => color && rgba(color, 0.2)};
`;

export const SubjectLabel = styled.span<{
  readonly isLight?: boolean;
  readonly color?: string;
}>`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  justify-content: flex-start;
  padding: 4px 12px;
  margin-bottom: 8px;
  font-weight: ${({ theme }) => theme.fontWeight.font500};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  line-height: 28px;
  border-radius: 6px;
  color: ${({ isLight, color, theme }) => (isLight ? color : theme.colors.nero)};
  background: ${({ color }) => color && rgba(color, 0.15)};
  ${mq(768)} {
    padding: 4px 6px;
    margin-bottom: 0px;
    font-size: ${({ theme }) => theme.fontsizes.body14};
    line-height: 20px;
    border-radius: 5px;
    background: ${({ theme }) => theme.colors.white};
  }
`;

export const PercentageNumber = styled.span<{ readonly color?: string }>`
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  color: ${({ theme, color }) => (color ? color : theme.colors.nero)};
`;

export const SubjectName = styled.h2`
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  font-size: ${({ theme }) => theme.fontsizes.h5};
  line-height: 40px;
  color: ${({ theme }) => theme.colors.nero};
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const InstractorName = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.font500};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.nero};
`;

export const CategoryName = styled(InstractorName)``;

export const RateWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const RateContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  .star-color {
    width: 20px;
  }
`;

export const SubjectRateNumber = styled(InstractorName)``;

export const Ellipse = styled.span`
  margin: 0 8px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray3};
`;

export const SubjectCoursesCount = styled(SubjectRateNumber)``;

// SUBJECT INFORMATION
export const NameofSubject = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  font-size: ${({ theme }) => theme.fontsizes.h5};
  line-height: 40px;
  color: ${({ theme }) => theme.colors.nero};
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const SubjectInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CreatedByWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
`;

export const CreatedBy = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.gray2};
`;

export const TeachersName = styled(Link)<{ readonly colored?: boolean }>`
  font-weight: ${({ theme, colored }) => (colored ? theme.fontWeight.font500 : theme.fontWeight.font400)};
  color: ${({ theme, colored }) => (colored ? theme.colors.red : theme.colors.nero)};
`;

export const Category = styled.span<{ readonly colored?: boolean }>`
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  line-height: 28px;
  color: ${({ theme, colored }) => (colored ? theme.colors.gray2 : theme.colors.nero)};
  margin-bottom: ${({ colored }) => (colored ? "4px" : "0px")};
`;

export const SubjectInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  ${mq(768)} {
    flex: auto;
    height: 100%;
    padding: 24px;
    position: absolute;
    top: 0%;
    left: 0%;
    justify-content: space-between;
  }
`;

// ABOUT
export const AboutSubject = styled.div`
  margin-bottom: 32px;
  ${mq(1024)} {
    padding: 0px 32px;
  }
  ${mq(768)} {
    padding: 0px 24px;
    margin-bottom: 20px;
  }
`;

export const AboutTitle = styled.h2`
  margin-bottom: 12px;
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  font-size: ${({ theme }) => theme.fontsizes.h6};
  line-height: 36px;
  color: ${({ theme }) => theme.colors.nero};
  ${mq(1024)} {
    margin-bottom: 16px;
  }
  ${mq(768)} {
    margin-bottom: 12px;
  }
`;

export const AboutDescription = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.gray1};
`;

// ACHIEVED
export const SubjectAchieved = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    span:nth-child(1) {
      margin-bottom: 4px;
      text-align: center;
      font-weight: ${({ theme }) => theme.fontWeight.font600};
      font-size: ${({ theme }) => theme.fontsizes.body18};
      line-height: 32px;
      color: ${({ theme }) => theme.colors.nero};
      ${mq(1024)} {
        font-size: ${({ theme }) => theme.fontsizes.h6};
        line-height: 36px;
      }
      ${mq(768)} {
        font-size: ${({ theme }) => theme.fontsizes.body18};
        line-height: 32px;
      }
    }
    span:nth-child(2) {
      text-align: center;
      font-weight: ${({ theme }) => theme.fontWeight.font400};
      font-size: ${({ theme }) => theme.fontsizes.body12};
      line-height: 20px;
      color: ${({ theme }) => theme.colors.gray1};
      ${mq(1024)} {
        font-size: ${({ theme }) => theme.fontsizes.body14};
        line-height: 24px;
      }
      ${mq(768)} {
        font-size: ${({ theme }) => theme.fontsizes.body12};
        line-height: 20px;
      }
    }
  }
  ${mq(1024)} {
    padding: 0px;
    margin-bottom: 24px;
  }
`;

export const Subscribers = styled.div`
  flex: 1;
  position: relative;
  &:not(:last-child)::after {
    content: "";
    position: absolute;
    right: 0%;
    top: 0%;
    height: 100%;
    width: 1px;
    background-color: ${({ theme }) => theme.colors.gray5_old};
  }
  @media (max-width: 380px) {
    flex: 0.3;
  }
`;

export const Duration = styled(Subscribers)`
  & > span:nth-child(1):lang(ar) {
    direction: rtl;
  }
  ${mq(768)} {
    padding: 0px 15px;
  }
  @media (max-width: 380px) {
    flex: 0.4;
  }
`;

export const VideosNumber = styled.div`
  flex: 1;
  position: relative;
  @media (max-width: 1024px) and (min-width: 768px) {
    &:not(:last-child)::after {
      content: "";
      position: absolute;
      right: 0%;
      top: 0%;
      height: 100%;
      width: 1px;
      background-color: ${({ theme }) => theme.colors.gray5_old};
    }
  }
  @media (max-width: 380px) {
    flex: 0.3;
  }
`;

export const Notes = styled.div`
  display: none !important;
  @media (max-width: 1024px) and (min-width: 768px) {
    flex: 1;
    display: flex !important;
  }
`;

// INSTRUCTED BY
export const InstructedByContainer = styled.div<{ readonly noPadding?: boolean }>`
  margin-bottom: 32px;
  padding: 0px;
  ${mq(1024)} {
    padding: ${({ noPadding }) => (noPadding ? "0px" : "0px 32px")};
  }
  ${mq(768)} {
    padding: ${({ noPadding }) => (noPadding ? "0px" : "0px 24px")};
  }
`;

export const InstructedByTitle = styled.h2`
  margin-bottom: 24px;
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  font-size: ${({ theme }) => theme.fontsizes.h6};
  line-height: 36px;
  color: ${({ theme }) => theme.colors.nero};
  ${mq(1024)} {
    margin-bottom: 16px;
  }
  ${mq(768)} {
    margin-bottom: 18px;
  }
`;

export const InstructedByLayout = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
`;

export const InstructedByImageContainer = styled(Link)<{ readonly isLivePage?: boolean }>`
  ${({ isLivePage }) =>
    isLivePage
      ? css`
          cursor: pointer;
          overflow: hidden;
          display: inline-block;
          position: relative;
          min-width: 132px;
          max-width: 132px;
          min-height: 132px;
          max-height: 132px;
          object-fit: cover;
          border-radius: 50%;
          border: 0.66px solid ${({ theme }) => theme.colors.gray5};
        `
      : css`
          display: none;
          @media (max-width: 1024px) and (min-width: 768px) {
            cursor: pointer;
            overflow: hidden;
            display: inline-block;
            position: relative;
            min-width: 132px;
            max-width: 132px;
            min-height: 132px;
            max-height: 132px;
            object-fit: cover;
            border-radius: 50%;
            border: 0.66px solid ${({ theme }) => theme.colors.gray5};
          }
        `}
`;

export const InstructedByImage = styled(Image)``;

export const Instructed = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const InstructedLayout = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  ${mq(768)} {
    gap: 12px;
    margin-bottom: 18px;
  }
`;

export const InstructedImageContainer = styled(Link)<{ readonly isLivePage?: boolean }>`
  ${({ isLivePage }) =>
    isLivePage
      ? css`
          display: none;
        `
      : css`
          position: relative;
          overflow: hidden;
          width: 56px;
          height: 56px;
          border-radius: 50%;
          object-fit: cover;
          cursor: pointer;
          border: 0.466667px solid ${({ theme }) => theme.colors.gray8};
          ${mq(768)} {
            width: 52px;
            height: 52px;
            border: 0.26px solid ${({ theme }) => theme.colors.gray5_old};
          }
          @media (max-width: 1024px) and (min-width: 767px) {
            display: none;
          }
        `}
`;

export const InstructedImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: ${({ theme }) => theme.colors.gray5_old};
`;

export const InstructorInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const InnstructedByName = styled(Link)`
  margin-bottom: 2px;
  font-weight: ${({ theme }) => theme.fontWeight.font600};
  font-size: ${({ theme }) => theme.fontsizes.body18};
  line-height: 32px;
  color: ${({ theme }) => theme.colors.nero};
  cursor: pointer;
  ${mq(768)} {
    margin-bottom: 6px;
  }
`;

export const InstructorRate = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

export const InstRateNumber = styled.span<{ readonly bolder?: boolean }>`
  font-weight: ${({ theme, bolder }) => (bolder ? theme.fontWeight.font400 : theme.fontWeight.font300)};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.nero};
  ${mq(1024)} {
    color: ${({ theme }) => theme.colors.nero};
  }
  ${mq(768)} {
    font-size: ${({ theme }) => theme.fontsizes.body14};
  }
`;

export const BasedRating = styled.span`
  @media (max-width: 1024px) and (min-width: 768px) {
    display: none;
  }
`;

export const InstructorBio = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  line-height: 28px;
  margin-top: 16px;
  color: ${({ theme }) => theme.colors.gray1};
`;

// RATING
export const RatingContainer = styled.div<{ readonly isSubscibed?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  ${mq(1024)} {
    padding: 0px 32px;
    flex-direction: row;
    gap: 33px;
    margin-bottom: 24px;
    ${({ isSubscibed }) =>
      isSubscibed &&
      css`
        align-items: flex-end;
      `}
  }
  ${mq(768)} {
    padding: 0px 24px;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 22px;
    ${({ isSubscibed }) =>
      isSubscibed &&
      css`
        align-items: unset;
      `}
  }
`;

export const RatingNumber = styled.div``;

export const RatingTitle = styled.h2<{ readonly isSubscibed?: boolean }>`
  margin-bottom: 12px;
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  font-size: ${({ theme }) => theme.fontsizes.h6};
  line-height: 36px;
  color: ${({ theme }) => theme.colors.nero};
  ${mq(1024)} {
    display: ${({ isSubscibed }) => (isSubscibed ? "none" : "block")};
  }
`;

export const IsReviewed = styled.div<{ readonly isSubscibed?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 22px;
  height: 100%;
  margin-bottom: 16px;
  ${mq(1024)} {
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 0px;
  }
  ${mq(768)} {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 22px;
    flex-wrap: nowrap;
  }
  @media (max-width: 350px) {
    gap: 5px;
  }
`;

export const MoreFromSubjectArrow = styled.div`
  width: 30px;
  & > :lang(ar) {
    transform: scale(-1, 1);
  }
`;

export const ReviewContainer = styled.div`
  display: flex;
  align-items: center;
  .big-star {
    width: 42px;
    ${mq(768)} {
      width: 30px;
    }
  }
`;

export const RatingNumberWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const ReviewRateNumber = styled.span`
  padding: 0px 8px 0px 12px;
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  font-size: 40px;
  line-height: 68px;
  color: ${({ theme }) => theme.colors.nero};
  ${mq(1024)} {
    padding: 0px 4px 0px 11px;
  }
  ${mq(768)} {
    font-size: ${({ theme }) => theme.fontsizes.h4};
    line-height: 48px;
    padding: 0px 8px 0px 10px;
  }
`;

export const ReviewsNumber = styled.span`
  margin-top: 14px;
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.gray1};
  ${mq(768)} {
    display: none;
  }
`;

export const GeneralRate = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  & > div:not(:last-child) {
    margin-bottom: 16px;
    ${mq(1024)} {
      margin-bottom: 12px;
    }
  }
`;

export const QualityRateTitle = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.font500};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.gray1};
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  ${mq(768)} {
    font-size: ${({ theme }) => theme.fontsizes.body14};
    line-height: 24px;
  }
`;

export const NoReviews = styled.div`
  padding: 63px 0px 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${mq(1024)} {
    padding: 140px 0px;
  }
  ${mq(768)} {
    padding: 32px 0px;
  }
`;

export const NoRateMessage = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  line-height: 28px;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray2};
  margin: 24px 0px;
`;

// REVIEWS
export const ReviewsComments = styled.div`
  margin-bottom: 32px;
  ${mq(1024)} {
    margin: 0px 32px 24px 32px;
  }
  ${mq(768)} {
    margin: 0px 24px 24px 24px;
  }
`;

export const UserRatingContainer = styled.div`
  padding: 20px;
  margin-bottom: 16px;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.gray7};
  position: relative;

  ${mq(1024)} {
    border-radius: 12px;
  }
  ${mq(768)} {
    border: 0.7px solid ${({ theme }) => theme.colors.gray5_old};
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.05);
  }
`;

export const RatingUserName = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
`;

export const Studentname = styled.h2`
  font-weight: ${({ theme }) => theme.fontWeight.font600};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.nero};
`;

export const RatingDate = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.font300};
  font-size: ${({ theme }) => theme.fontsizes.body14};
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray2};
`;

export const ReviewComment = styled.p<{
  readonly more?: boolean;
}>`
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.gray1};
  margin-top: 17.71px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${({ more }) => (more ? "2" : "300")};
  -webkit-box-orient: vertical;
  max-height: ${({ more }) => (more ? "60px" : "2500px")};
  overflow: hidden;
  transition: 0.3s ease all;
`;

export const Readmore = styled.span`
  position: absolute;
  bottom: 5px;
  right: 20px;
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.body14};
  color: ${({ theme }) => theme.colors.seagule};
  text-decoration: underline;
`;

export const ShowMore = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.seagule};
  img {
    width: 13px;
  }
`;

export const ShowMoreTitle = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.font600};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  line-height: 28px;
`;

// MORE FROM
export const MoreFromContainer = styled.div<{
  readonly hasShadow?: boolean;
  readonly shadowposition?: string;
}>`
  margin-bottom: 6px;
  display: grid;
  .swiper {
    width: 100%;
  }
  ${({ hasShadow, shadowposition }) =>
    hasShadow &&
    css`
      position: relative;
      overflow: hidden;
      &::after {
        content: "";
        position: absolute;
        top: 52px;
        ${shadowposition === SLIDER_ISBEGINNING
          ? css`
              right: 0;
            `
          : css`
              left: 0;
            `}
        width: 20px;
        height: 100%;
        background: ${shadowposition === SLIDER_ISBEGINNING
          ? "linear-gradient(90deg,rgba(26, 26, 26, 0) 0%,rgba(26, 26, 26, 0.2) 100%)"
          : "linear-gradient(-90deg,rgba(26, 26, 26, 0) 0%,rgba(26, 26, 26, 0.2) 100%)"};
        z-index: 10;
      }
    `}
  .swiper-slide {
    width: 262px !important;
  }
`;

export const MoreFromHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const MoreFromTitle = styled.h2`
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  font-size: ${({ theme }) => theme.fontsizes.h6};
  line-height: 36px;
  color: ${({ theme }) => theme.colors.nero};
`;

export const MoreFromBtns = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const ArrowsBtn = styled.button`
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 28px;
  height: 28px;
  border-radius: 6px;
  transition:
    0.2s ease opacity,
    0.2s ease background-color;
  ${({ theme }) => css`
    background-color: ${theme.colors.gray5_old};
    &:disabled,
    &[disabled] {
      cursor: not-allowed;
      svg {
        opacity: 0.2;
      }
    }
  `}
  img {
    width: 8px;
  }
  svg:lang(ar) {
    transform: scale(-1, 1);
  }
`;

// COURSES CARD

export const CoursesCardContainer = styled.div`
  position: relative;
  overflow-y: hidden;
  height: auto;
  max-height: 100%;
  width: 100%;
  min-width: 446px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  ${mq(1024)} {
    height: 100%;
    border-radius: 0px;
    min-width: 100%;
    box-shadow: none;
  }
`;

export const CoursesWrapper = styled.div<{
  isPackage?: boolean;
  iscoursePage?: boolean;
}>`
  ${({ iscoursePage }) =>
    iscoursePage
      ? css`
          max-height: 90%;
          min-height: 200px;
        `
      : css`
          height: calc(90% - 8px);
        `}
  overflow-y: auto;
  margin-right: 6px;
  scrollbar-color: #e1e1e1 white;
  scrollbar-width: thin;
  padding: ${({ isPackage }) => (isPackage ? "0 28px 28px" : "unset")};
  ${mq(1024)} {
    padding: ${({ isPackage }) => (isPackage ? "0px 0" : "unset")};
    overflow-y: unset;
  }
  &::-webkit-scrollbar {
    display: block;
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    margin-bottom: 20px;
    margin-top: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray5};
    border-radius: 9px;
  }
`;

export const CoursesCardTitle = styled.h2<{
  readonly isCoursePage?: boolean;
  readonly scrollShadow?: any;
  readonly headerShadow?: boolean;
}>`
  padding: 18px 20px 10px 25px;
  position: sticky;
  top: 0%;
  left: 0%;
  right: 0%;
  width: 100%;
  background-color: ${({ isCoursePage, theme }) => (isCoursePage ? theme.colors.gray7 : theme.colors.white)};
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  font-size: ${({ theme }) => theme.fontsizes.h6};
  line-height: 36px;
  color: ${({ theme }) => theme.colors.nero};
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  z-index: 30;
  box-shadow: ${({ scrollShadow, headerShadow }) =>
    scrollShadow > 0 || headerShadow ? " 0px 2px 10px rgba(0, 0, 0, 0.07)" : "none"};
  transition: box-shadow 0.3s;
  ${mq(1024)} {
    padding: 0px 32px;
  }
  ${mq(768)} {
    padding: 0px 24px;
  }
`;

export const CourseDetailsContainer = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 20px;
  padding: 8px 8px;
  color: ${({ theme }) => theme.colors.nero};
  cursor: pointer;
  transition: all 0.5s ease;
  &:first-child {
    margin: 0px 20px 8px;
  }
  @media (min-width: 1024px) {
    &:last-child {
      margin-bottom: 50px;
    }
  }
  &:not(:last-child)::after {
    content: "";
    position: absolute;
    left: 0%;
    bottom: -5px;
    width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.gray5_old};
    opacity: 60%;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray6};
    border-radius: 8px;
  }
  ${mq(1024)} {
    margin: 10px 24px;
    padding: 16px 8px;
    &:first-child {
      margin: 10px 24px;
    }
  }
  ${mq(768)} {
    margin: 10px 16px;
    padding: 20px 8px;
    &:first-child {
      margin: 10px 16px;
    }
  }
`;

export const CourseTitle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const CourseName = styled.h2`
  margin-bottom: 8px;
  font-weight: ${({ theme }) => theme.fontWeight.font600};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.nero};
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const Numbers = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const InfoNumbers = styled(Numbers)`
  & > p:nth-child(1):lang(ar) {
    direction: rtl;
  }
`;

export const Dot = styled.span`
  display: inline-block;
  font-size: ${({ theme }) => theme.fontsizes.body12};
  margin: 0px 12px;
  color: ${({ theme }) => theme.colors.gray3};
`;

export const CoursesDot = styled(Dot)`
  margin: 0px 8px;
`;

export const CoursesNumberInfo = styled.p``;

export const DetailsNumbersValues = styled.p<{ readonly bolder?: boolean }>`
  display: inline-block;
  font-weight: ${({ theme, bolder }) => (bolder ? theme.fontWeight.font500 : theme.fontWeight.font400)};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.gray1};
  text-decoration: capitalize;
  ${mq(1024)} {
    font-weight: ${({ theme, bolder }) => (bolder ? theme.fontWeight.font500 : theme.fontWeight.font300)};
    font-size: ${({ theme, bolder }) => (bolder ? theme.fontsizes.body16 : theme.fontsizes.body14)};
    line-height: ${({ bolder }) => (bolder ? "28px" : "20px")};
  }
  ${mq(768)} {
    font-size: ${({ theme }) => theme.fontsizes.body14};
    line-height: ${({ bolder }) => (bolder ? "24px" : "20px")};
  }
`;

export const CoursesNumbersValues = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.gray1};
  ${mq(768)} {
    font-size: ${({ theme }) => theme.fontsizes.body14};
    line-height: 20px;
  }
`;

export const Prices = styled.div<{
  readonly direction?: string;
  readonly center?: boolean;
  readonly size?: string;
  readonly lineHeight?: string;
}>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  align-items: ${({ center }) => (center ? "center" : "flex-end")};
  justify-content: center;
  gap: 10px;
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ size, theme }) => (size === "large" ? theme.fontsizes.body16 : theme.fontsizes.body14)};
  line-height: ${({ lineHeight }) => (lineHeight === "large" ? "24px" : "20px")};
`;

export const OldPrice = styled.div`
  line-height: 20px;
  text-decoration: line-through;
  color: ${({ theme }) => theme.colors.gray2};
`;

export const Price = styled.div<{
  readonly isLight?: boolean;
  readonly color?: string;
}>`
  line-height: 28px;
  min-width: 69px;
  min-height: 32px;
  padding: 2px 12px;
  color: ${({ isLight, color, theme }) => (isLight ? color : theme.colors.nero)};
  background: ${({ color }) => color && rgba(color, 0.15)};
  border-radius: 40px;
  text-align: center;
`;

export const Free = styled(Price)``;

export const ProgressBarContainer = styled.div`
  svg {
    transform: rotate(90deg) !important;
  }
  &:lang(ar) {
    svg {
      transform: rotate(-90deg) !important;
    }
  }
  .RCP {
    width: 75px !important;
    height: 50px !important;
    transform: translateY(-23px);
  }
`;

export const Indicator = styled.div`
  position: absolute;
  top: 80%;
  left: 48%;
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.body14};
  line-height: 20px;
  color: ${({ theme }) => theme.colors.nero};
`;

export const CompeleteIndicator = styled.div`
  .checkIcon {
    position: absolute;
    top: 70%;
    left: 46%;
    font-size: 26px;
    color: ${({ theme }) => theme.colors.green};
    transform: rotate(0deg) !important;
    &:lang(ar) {
      transform: rotate(0deg) !important;
    }
  }
`;

export const Arrow = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
  text-align: center;
  .arrowIcon {
    width: 8px;
  }
  & > :lang(ar) {
    transform: rotate(180deg);
  }
  @media (max-width: 414px) {
    display: none;
  }
`;

// PAYMENT CARD
export const PaymentCardContainer = styled.div<{
  readonly isLivePage?: boolean;
  readonly isCoursePage?: boolean;
  readonly packageWithOptions?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  margin-top: 20px;
  min-height: 96px;
  width: 100%;
  overflow: hidden;
  min-width: ${({ isCoursePage }) => (isCoursePage ? "388px" : "446px")};
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  ${ButtonContainer} {
    width: 180px;
    button {
      align-items: center;
      height: 64px;
      font-size: ${({ theme }) => theme.fontsizes.body16};
      font-weight: ${({ theme }) => theme.fontWeight.font400};
      img {
        margin-left: 12px;
      }
    }
  }
  ${({ isLivePage }) =>
    isLivePage
      ? css`
          position: fixed;
          bottom: 0;
          left: 0;
          width: 100%;
          border-radius: 0px;
          z-index: 98 !important;
        `
      : css``}

  ${mq(1024)} {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    min-width: 100%;
    padding: 12px 32px;
    border-radius: 0px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    z-index: 98 !important;
    margin-top: 0px;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-overflow-scrolling: touch;
  }
  ${mq(768)} {
    padding: 12px 24px;
  }
  @media (max-width: 350px) {
    padding: 12px 10px;
  }
  ${({ packageWithOptions }) =>
    packageWithOptions &&
    css`
      flex-wrap: wrap;
      flex: 0 0 auto;
      max-width: 446px;
    `}
`;
export const PackageOptionsWrapper = styled.div`
  flex: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray5_old};
  padding-bottom: 12px;
  margin-bottom: 12px;
`;
export const PackageHeaderOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 4px;
  background: ${({ theme }) => theme.colors.gray5_old};
  height: 40px;
`;
export const OptionBtn = styled.button<{
  readonly isActive?: boolean;
}>`
  ${stripButton};
  white-space: nowrap;
  background: ${({ theme, isActive }) => (isActive ? theme.colors.white : "transparent")};
  font-size: ${({ theme }) => theme.fontsizes.body14};
  font-weight: ${({ theme, isActive }) => (isActive ? theme.fontWeight.font500 : theme.fontWeight.font400)};
  color: ${({ theme, isActive }) => (isActive ? theme.colors.nero : theme.colors.gray3)};
  min-height: 32px;
  max-height: 32px;
  padding: 4px 16px;
  border-radius: 6px;
  flex: 1;
  margin-right: 4px;
  transition: all 0.2s ease;
  &:last-of-type {
    margin-right: 0;
  }
`;
export const PackageOptionDesc = styled.p`
  font-size: ${({ theme }) => theme.fontsizes.body14};
  line-height: 1.7;
  margin-top: 8px;
`;
export const PaymentCardDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  /* height: 100%; */
`;

export const DiscountValue = styled.span<{
  readonly isLight?: boolean;
  readonly color?: string;
}>`
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.body14};
  line-height: 24px;
  color: ${({ isLight, color, theme }) => (isLight ? color : theme.colors.nero)};
  ${mq(1024)} {
    line-height: 20px;
  }
`;

export const PaymentAdvice = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.gray2};
  ${mq(1024)} {
    line-height: 24px;
  }
`;

export const PricesValues = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.font600};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  line-height: 28px;
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${({ theme }) => theme.colors.nero};
  ${mq(1024)} {
    line-height: 24px;
  }
  ${mq(768)} {
    line-height: 28px;
  }
`;

export const UndiscountedPrice = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.font600};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  line-height: 24px;
  text-decoration-line: line-through;
  color: ${({ theme }) => theme.colors.gray3};
`;

export const PaymentBtn = styled(CommonButton)<{
  readonly isLight?: boolean;
  readonly color?: string;
  readonly isDisabled?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 64px;
  font-style: normal;
  font-size: ${({ theme }) => theme.fontsizes.body16};
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  line-height: 28px;
  border-radius: 8px;
  cursor: ${({ isDisabled }) => (isDisabled ? "no-drop" : "pointer")};
  color: ${({ isLight, theme }) => (isLight ? theme.colors.white : theme.colors.nero)};
  background-color: ${({ color }) => color};
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
  text-decoration: none;
  ${mq(1024)} {
    width: 140px;
  }
`;

// MORE FROM AND SIMILAR TAB
export const SimilarContainer = styled.div`
  .swiper-slide {
    width: 262px !important;
  }
  overflow-y: auto;
  padding: 0px 32px;
  ${mq(768)} {
    padding: 0px 24px;
  }
`;

export const SimilarSubjectsContainer = styled.div`
  margin-bottom: 24px;
`;

export const MoreSubjects = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
`;

export const MoreSimilarSubjectsTitle = styled.h2`
  margin-bottom: 16px;
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  font-size: ${({ theme }) => theme.fontsizes.h6};
  line-height: 32px;
  color: ${({ theme }) => theme.colors.nero};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ShowArrow = styled.div`
  display: flex;
  align-items: center;
  min-width: 90px;
  & > a {
    font-weight: ${({ theme }) => theme.fontWeight.font400};
    font-size: ${({ theme }) => theme.fontsizes.body16};
    line-height: 28px;
    color: ${({ theme }) => theme.colors.gray2};
  }
  img {
    width: 8px;
    margin: 0px 8px;
    &:lang(ar) {
      transform: scale(-1, 1);
    }
  }
`;

// MORE FROM PAGE
export const MoreFrom = styled.div`
  height: calc(100vh - 109px);
  transform: translateY(109px);
  overflow-y: auto;
  padding: 28px 32px 32px;
  ${mq(992)} {
    height: calc(100vh - 84px);
    transform: translateY(84px);
  }
  ${mq(576)} {
    height: calc(100vh - 72px);
    transform: translateY(72px);
  }
  ${mq(768)} {
    padding: 24px;
  }
`;

export const CardStar = styled.img`
  margin: 0px 5px;
`;

export const ItemContainer = styled.div``;

export const MoreItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12.5px 0px;
  position: relative;
  cursor: pointer;
  &:not(:nth-last-child(-n + 1))::after {
    content: "";
    position: absolute;
    bottom: 0%;
    left: 0%;
    width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.gray5_old};
    opacity: 60%;
  }
  ${mq(768)} {
    &:not(:nth-last-child(-n + 1))::after {
      content: "";
      position: absolute;
      bottom: 0%;
      left: 0%;
      width: 100%;
      border: 1px solid ${({ theme }) => theme.colors.gray5_old};
      opacity: 55%;
    }
  }
`;

export const MoreFromData = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const SubImage = styled(Image)`
  min-width: 107px;
  max-width: 107px;
  height: 80px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.gray5_old};
`;

export const SubImagePlaceholder = styled.div`
  min-width: 107px;
  max-width: 107px;
  height: 80px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.gray5_old};
`;

export const ItemData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ItemLabel = styled.span<{
  readonly isLight?: boolean;
  readonly color?: string;
}>`
  background: ${({ color }) => color && rgba(color, 0.15)};
  border-radius: 4px;
  padding: 2px 6px;
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.body14};
  line-height: 20px;
  min-width: 71px;
  height: 24px;
  text-align: center;
  color: ${({ isLight, color, theme }) => (isLight ? color : theme.colors.nero)};
  margin-bottom: 6px;
`;

export const ItemTitle = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.nero};
  margin-bottom: 8px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const ItemsNumbers = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  & > div {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const CoursesCount = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.body14};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.nero};
`;

export const SimilarRate = styled.span`
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.body14};
  line-height: 20px;
  color: ${({ theme }) => theme.colors.white};
  span {
    font-size: 11px;
    margin: 0px 8px;
  }
`;

export const SimilarsRate = styled(SimilarRate)`
  color: ${({ theme }) => theme.colors.nero};
  gap: 0px;
  span {
    color: ${({ theme }) => theme.colors.gray8};
  }
`;

export const CoursesNumbers = styled.span``;

// REUSABLE COMPONENTS
export const Buttons = styled.button`
  font-weight: ${({ theme }) => theme.fontWeight.font600};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  line-height: 28px;
  text-align: center;
  border: none;
  border-radius: 8px;
  cursor: pointer;
`;

export const Stars = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .rating-stars {
    margin: "0px 2.5px";
    width: "17.9px";
  }
  .rating-stars-empty {
    margin: "0px 2.5px";
    width: "17.4px";
  }
  .rating-stars-empty:lang(ar) {
    direction: rtl;
    transform: scale(-1, 1);
  }
  .rating-stars:lang(ar) {
    direction: rtl;
    transform: scale(-1, 1);
  }
`;

export const SingelCourse = styled.a``;

export const AddReviewBtn = styled(Buttons)<{
  readonly isLight?: boolean;
  readonly reviews?: number;
  readonly color?: string;
}>`
  padding: 10px 28px;
  background-color: ${({ isLight, color }) => (isLight ? color && rgba(color, 0.15) : color)};
  color: ${({ isLight, color, theme }) => (isLight ? color : theme.colors.nero)};
  font-weight: ${({ reviews }) => (reviews <= 0 ? "700" : "400")};
  width: ${({ reviews }) => (reviews === 0 ? "229px" : "157px")};
  ${mq(1024)} {
    width: ${({ reviews }) => (reviews === 0 ? "229px" : "100%")};
  }
  ${mq(768)} {
    padding: ${({ reviews }) => (reviews <= 0 ? "" : "6px 20px")};
    width: ${({ reviews }) => (reviews <= 0 ? "229px" : "128px")};
    font-size: ${({ reviews }) => (reviews <= 0 ? "16px" : "14px")};
  }
`;

export const SubjectSpinner = styled.div`
  .box {
    display: flex;
    justify-content: center;
  }
  .spinner_circle {
    width: 80px;
    height: 80px;
    margin-top: 120px;
  }
`;

// POPUPS BACKGROUNDS
export const BlurPopupBackground = styled.div`
  z-index: 200;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  background-color: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(6px);
  animation: ${BluredBg} 0.2s ease-in;
  cursor: pointer;
`;

export const BlurBackground2 = styled.div`
  z-index: 400;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0%;
  left: 0%;
  top: 0%;
  bottom: 0%;
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(6px);
  animation: ${BluredBg} 0.2s ease-in;
  cursor: pointer;
`;

// POPUPS BUTTONS
export const PopupWideBtn = styled.div<{ readonly color?: string }>`
  padding: 10px 0px;
  width: 100%;
  font-weight: ${({ theme }) => theme.fontWeight.font600};
  font-size: ${({ theme }) => theme.fontsizes.body18};
  line-height: 32px;
  text-align: center;
  border: none;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ color }) => color};
  cursor: pointer;
`;

export const PopupThankBtn = styled.div<{ readonly color?: string }>`
  padding: 10px 0px;
  width: 100%;
  font-weight: ${({ theme }) => theme.fontWeight.font600};
  font-size: ${({ theme }) => theme.fontsizes.body18};
  line-height: 32px;
  text-align: center;
  border: none;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ color }) => color};
  cursor: pointer;
`;

export const PopupRatingBtn = styled.button`
  flex: 1;
  border: none;
  border-radius: 8px;
  font-weight: ${({ theme }) => theme.fontWeight.font600};
  font-size: ${({ theme }) => theme.fontsizes.body18};
  line-height: 32px;
  height: 64px;
  cursor: pointer;
`;

export const PopupSubscribeBtn = styled.a`
  flex: 1;
  padding: 11px 28px;
  font-weight: ${({ theme }) => theme.fontWeight.font600};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  line-height: 28px;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
`;

// LOGIN POPUP
// export const LoginPopupContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   height: calc(100vh - 109px);
//   position: absolute;
//   top: 0%;
//   left: 0%;
//   top: 0%;
//   bottom: 0%;
//   ${mq(895)} {
//     height: calc(100vh - 84px);
//   }
//   ${mq(768)} {
//     height: calc(100vh - 72px);
//   }
// `;

export const LoginPopup = styled.div`
  width: 480px;
  height: 550px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid rgba(26, 26, 26, 0.05);
  box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.04);
  border-radius: 15px;
  z-index: 300;
  padding: 24px;
  display: flex;
  flex-direction: column;
  animation: ${scaleUp} 0.3s ease-in;
  ${mq(768)} {
    width: 100vw;
    height: 100vh;
    border-radius: 0px;
    overflow-y: auto;
  }
  .close {
    font-size: ${({ theme }) => theme.fontsizes.h5};
    color: ${({ theme }) => theme.colors.gray2};
    align-self: flex-end;
    cursor: pointer;
  }
`;

// NOT SUBSCRIBES POPUP
export const NotSubscribePopupContainer = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  padding: 0px 24px;
  top: 0%;
  left: 0%;
  top: 0%;
  bottom: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NotSubscribePopup = styled.div`
  padding: 32px;
  width: 342px;
  height: 242px;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.white};
  animation: ${scaleUp} 0.3s ease-in;
  z-index: 500;
  &:lang(tr) {
    height: auto;
  }
  ${mq(768)} {
    width: 100%;
    padding: 24px;
    height: 220px;
  }
  @media (max-width: 375px) {
    width: 272px;
    height: 274px;
  }
`;

export const SubscribePopupTitle = styled.h2`
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  font-size: ${({ theme }) => theme.fontsizes.h5};
  line-height: 40px;
  color: ${({ theme }) => theme.colors.nero};
  text-align: center;
  margin-bottom: 8px;
`;

export const SubscribePopupMessage = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  line-height: 28px;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray2};
  margin-bottom: 24px;
`;

export const NotSubscribeBtns = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const Dismiss = styled(PopupSubscribeBtn)<{ readonly color?: string }>`
  color: ${({ color }) => color};
  background: ${({ color }) => color && rgba(color, 0.15)};
  @media (max-width: 375px) {
    width: 100px;
    height: 46px;
    padding: 9px 9.39px;
  }
`;

export const Subscribe = styled(PopupSubscribeBtn)<{ readonly color?: string }>`
  line-height: 32px;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ color }) => color};
  @media (max-width: 375px) {
    width: 100px;
    height: 46px;
    padding: 7px 8.5px;
  }
`;

// ADD RATING POPUP
export const RatingMadolContainer = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0%;
  right: 0%;
  bottom: 0%;
  top: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MadolContainer = styled.form`
  z-index: 300;
  background-color: ${({ theme }) => theme.colors.white};
  width: 732px;
  height: 600px;
  border-radius: 16px;
  animation: ${scaleUp} 0.3s ease-in;
  ${mq(1024)} {
    width: 516px;
    height: 776px;
  }
  ${mq(768)} {
    width: 100%;
    height: 100vh;
    border-radius: 0px;
  }
  overflow-y: auto;
  scrollbar-color: #e1e1e1 white;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: block;
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray5};
    border-right: none;
    border-left: none;
    border-radius: 9px;
  }
  &::-webkit-scrollbar-button {
    height: 5px;
  }
  &::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 10px;
  }
  &::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 50px;
  }
`;

export const MadolHeader = styled.h2`
  font-weight: ${({ theme }) => theme.fontWeight.font800};
  font-size: ${({ theme }) => theme.fontsizes.h6};
  line-height: 32px;
  color: ${({ theme }) => theme.colors.nero};
  padding: 32px 32px 28px;
  ${mq(768)} {
    padding: 32px 24px 28px;
  }
`;

export const MadolContent = styled.div`
  padding: 0px 32px 20px;
  height: 100%;
  ${mq(768)} {
    padding: 0px 24px 28px;
    margin-bottom: 120px;
  }
  @media (max-width: 320px) {
    margin-bottom: 230px;
  }
  @media (device-height: 480px) {
    margin-bottom: 320px;
  }
`;

export const GeneralRating = styled.div`
  background-color: ${({ theme }) => theme.colors.yellow};
  width: 222px;
  height: 8px;
  margin-bottom: 32px;
`;

export const CircleContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-width: 250px;
  justify-content: space-between;
`;

export const CircleRating = styled.div`
  margin: 0px 0px 23px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
  z-index: 600;
  .active_icon {
    font-size: 26px;
    color: ${({ theme }) => theme.colors.yellow};
    margin-right: 20px;
    ${mq(1024)} {
      margin-right: 60px;
    }
    ${mq(768)} {
      margin-right: 25px;
    }
    @media (max-width: 414px) {
      margin-right: 30px;
    }
    @media (max-width: 376px) {
      margin-right: 25px;
    }
    @media (max-width: 350px) {
      margin-right: 20px;
    }
    @media (max-width: 320px) {
      margin-right: 15px;
    }
  }
  .deactive_icon {
    font-size: 26px;
    color: ${({ theme }) => theme.colors.gray5};
    margin-right: 20px;
    ${mq(1024)} {
      margin-right: 60px;
    }
    ${mq(768)} {
      margin-right: 25px;
    }
    @media (max-width: 414px) {
      margin-right: 30px;
    }
    @media (max-width: 376px) {
      margin-right: 25px;
    }
    @media (max-width: 350px) {
      margin-right: 20px;
    }
    @media (max-width: 320px) {
      margin-right: 15px;
    }
  }
`;

export const CirclesContainer = styled.div`
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .rate:lang(ar) {
    direction: rtl;
  }
`;

export const RatingValue = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  ${mq(1024)} {
    transform: translateX(-40px);
  }
  ${mq(768)} {
    transform: translateX(-10px);
  }
  @media (max-width: 320px) {
    transform: translateX(3px);
  }

  & > span {
    font-weight: ${({ theme }) => theme.fontWeight.font400};
    font-size: ${({ theme }) => theme.fontsizes.body18};
    line-height: 28px;
    color: ${({ theme }) => theme.colors.nero};
  }
  img {
    width: 25px;
  }
`;

export const BackBar = styled.div`
  width: 180px;
  height: 8px;
  position: absolute;
  top: 30%;
  left: 20px;
  background-color: ${({ theme }) => theme.colors.gray5_old};
  transform: translateY(-50%);
  z-index: 111;
  direction: ltr;
  &:lang(ar) {
    direction: rtl;
  }
  ${mq(1024)} {
    width: 330px;
  }
  ${mq(768)} {
    width: 200px;
  }
  @media (max-width: 414px) {
    width: 220px;
  }
  @media (max-width: 376px) {
    width: 205px;
  }
  @media (max-width: 350px) {
    width: 175px;
  }
  @media (max-width: 320px) {
    width: 160px;
  }
`;

export const Bar = styled.span<{ readonly width?: string }>`
  display: block;
  height: 100%;
  width: ${({ width }) => width};
  transition: all 0.3s ease;
  background-color: ${({ theme }) => theme.colors.casablanca};
  z-index: 222;
`;

export const DetailedRate = styled.div<{ readonly color?: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 24px;
  .opened-switch-bg {
    background-color: ${({ color }) => color};
  }
`;

export const DetailedRateTitle = styled.h2`
  font-weight: ${({ theme }) => theme.fontWeight.font800};
  font-size: ${({ theme }) => theme.fontsizes.h6};
  line-height: 32px;
  color: ${({ theme }) => theme.colors.nero};
`;

export const SwitchBtn = styled.div`
  position: relative;
  width: 48px;
  height: 24px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.gray8};
  z-index: 2;
  & > span {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    transition: all 0.3s ease;
    background-color: ${({ theme }) => theme.colors.white};
    z-index: 6;
  }
  .opened-switch {
    transform: translateX(150%);
  }
`;

export const DetailedRating = styled.div`
  font-size: ${({ theme }) => theme.fontsizes.body16};
  display: flex;
  justify-content: space-between;
  & > div {
    @media (min-width: 1024px) {
      &:last-child:lang(ar) {
        transform: translateX(-50px);
      }
    }
    & > div {
      & > span {
        font-weight: ${({ theme }) => theme.fontWeight.font400};
        font-size: ${({ theme }) => theme.fontsizes.body16};
        line-height: 24px;
        color: ${({ theme }) => theme.colors.nero};
        margin-bottom: 16px;
        display: block;
      }
    }
  }
  ${mq(1024)} {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`;

export const Reviews = styled.div``;

export const ReviewsTitle = styled.h2`
  font-weight: ${({ theme }) => theme.fontWeight.font800};
  font-size: ${({ theme }) => theme.fontsizes.h6};
  line-height: 32px;
  color: ${({ theme }) => theme.colors.nero};
  padding-bottom: 16px;
`;

export const ReviewsMessage = styled.textarea`
  padding: 18px 20px;
  width: 100%;
  height: 181px;
  background: ${({ theme }) => theme.colors.gray6};
  border-radius: 10px;
  border: none;
  &::placeholder {
    font-weight: ${({ theme }) => theme.fontWeight.font400};
    font-size: ${({ theme }) => theme.fontsizes.body16};
    line-height: 24px;
    color: ${({ theme }) => theme.colors.gray2};
  }
`;

export const MadolBtnsContainer = styled.div<{ readonly color?: string }>`
  margin-top: auto;
  border-top: 1px solid ${({ theme }) => theme.colors.gray6};
  background-color: ${({ theme }) => theme.colors.white};
  position: sticky;
  bottom: 0%;
  left: 0%;
  width: 100%;
  height: 128px;
  padding: 32px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  z-index: 800;
  ${mq(768)} {
    padding: 24px;
    height: 112px;
  }
  .act-btn {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ color }) => color};
    cursor: pointer;
  }
`;

export const DismissBtn = styled(PopupRatingBtn)<{ readonly color?: string }>`
  background-color: ${({ color }) => color && rgba(color, 0.15)};
  color: ${({ color }) => color};
`;

export const PublishBtn = styled(PopupRatingBtn)`
  background: ${({ theme }) => theme.colors.gray8};
  color: ${({ theme }) => theme.colors.white};
  @media (max-width: 360px) {
    line-height: 1.2;
  }
`;

// ALERT MESSAGE POPUP
export const AlertMessageContainer = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0%;
  left: 0%;
  top: 0%;
  bottom: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 24px;
`;

export const AlertMessagePopup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  width: 342px;
  height: 242px;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.white};
  animation: ${scaleUp} 0.3s ease-in;
  z-index: 500;
  &:lang(tr) {
    height: auto;
  }
  ${mq(768)} {
    width: 100%;
    height: 224px;
    & > h2 {
      font-size: ${({ theme }) => theme.fontsizes.h6};
      line-height: 32px;
    }
    & > p {
      color: ${({ theme }) => theme.colors.nero};
    }
  }
  @media (max-width: 375px) {
    height: auto;
  }
`;

export const AlertMessageTitle = styled.h2`
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  font-size: ${({ theme }) => theme.fontsizes.h5};
  line-height: 40px;
  text-align: center;
  color: ${({ theme }) => theme.colors.nero};
  margin-bottom: 12px;
`;

export const AlertMessages = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  line-height: 28px;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray2};
  margin-bottom: 24px;
`;

// THANK POPUP
export const ThankPopupContainer = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0%;
  left: 0%;
  top: 0%;
  bottom: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
`;

export const ThankPopup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  width: 366px;
  height: 361px;
  border-radius: 16px;
  animation: ${scaleUp} 0.3s ease-in;
  background: ${({ theme }) => theme.colors.white};
  z-index: 500;
  &:lang(tr) {
    height: auto;
  }
  ${mq(768)} {
    width: 100%;
  }
  @media (max-width: 370px) {
    height: auto;
  }
`;

export const ThankTitle = styled.h2`
  font-weight: ${({ theme }) => theme.fontWeight.font800};
  font-size: ${({ theme }) => theme.fontsizes.h6};
  line-height: 32px;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 16px;
`;

export const ThankUserImage = styled(Image)`
  border-radius: 50%;
`;

export const ThankUserImagePlaceHolder = styled.div`
  width: 141px;
  height: 141px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray5_old};
`;

export const ThankMessage = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.colors.nero};
  margin-bottom: 28px;
  margin-top: 16px;
`;

export const StyledLoginModal = styled(Rodal)`
  z-index: 101;
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(6px);
  .rodal-mask {
    height: 100vh;
    position: fixed;
    top: 0%;
    left: 0%;
    top: 0%;
    bottom: 0%;
    width: 100vw;
    background: transparent;
  }
  .rodal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    box-shadow: none;
    ${mq(768)} {
      min-height: 100vh;
      min-width: 100vw;
    }
  }
`;

export const TamaraLogoWrapper = styled.div`
  line-height: 0;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
`;
export const TamaraBannerWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.seagullNeutrals};
  border-radius: 10px;
  padding: 8px 16px 8px 12px;
  position: relative;
  width: 100%;
  margin-top: 10px;
  @media (min-width: 1024px) {
    margin-top: 0;
    margin-bottom: 32px;
  }
`;
export const TamaraBannerInfo = styled.div`
  margin-right: 10px;
  line-height: 20px;
`;
export const TamaraBannerTitle = styled.h4`
  font-size: ${({ theme }) => theme.fontsizes.body14};
  font-weight: ${({ theme }) => theme.fontWeight.font600};
`;

export const TamaraBannerDesc = styled.p`
  font-size: ${({ theme }) => theme.fontsizes.body12};
  font-weight: ${({ theme }) => theme.fontWeight.font400};
`;
export const TamaraLoading = styled.div<{ readonly forLogo?: boolean }>`
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 1;
  line-height: 1;
  cursor: not-allowed;
  ${({ forLogo }) =>
    forLogo
      ? css`
          top: 50%;
          transform: translateY(-42%);
          left: 0;
          width: calc(100% + 30px);
          justify-content: flex-end;
        `
      : css`
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 10px;
          background-color: ${({ theme }) => rgba(theme.colors.white, 0.3)};
          justify-content: center;
        `}
`;
