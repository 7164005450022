import { mq } from "@Utils/StyleUtil";
import Image from "next/image";
import styled, { css } from "styled-components";

export const SearchCardsWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0px;
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    bottom: 0%;
    left: 0%;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.gray5_old};
  }
  &:last-of-type::after {
    display: none;
  }
  ${mq(1024)} {
    padding: 14px 0px;
  }
`;

export const SearchCardContent = styled.div`
  display: flex;
  gap: 16px;
`;

export const ImageScearchContainer = styled.div<{ readonly isCircle?: boolean }>`
  min-width: ${({ isCircle }) => (isCircle ? "76px" : "177px")};
  max-width: ${({ isCircle }) => (isCircle ? "76px" : "177px")};
  min-height: ${({ isCircle }) => (isCircle ? "76px" : "132px")};
  max-height: ${({ isCircle }) => (isCircle ? "76px" : "132px")};
  ${mq(768)} {
    min-width: ${({ isCircle }) => (isCircle ? "72px" : "108px")};
    max-width: ${({ isCircle }) => (isCircle ? "72px" : "108px")};
    min-height: ${({ isCircle }) => (isCircle ? "72px" : "80px")};
    max-height: ${({ isCircle }) => (isCircle ? "72px" : "80px")};
  }
`;

export const SubjectCardImage = styled(Image)<{ readonly isCircle?: boolean }>`
  border-radius: ${({ isCircle }) => (isCircle ? "50%" : "10px")};
  overflow: hidden;
  ${mq(768)} {
    border-radius: ${({ isCircle }) => (isCircle ? "50%" : "7.2px")};
  }
`;

export const SubjectCardImagePlaceHolder = styled.div<{ readonly isCircle?: boolean }>`
  display: block;
  min-width: ${({ isCircle }) => (isCircle ? "76px" : "177px")};
  max-width: ${({ isCircle }) => (isCircle ? "76px" : "177px")};
  min-height: ${({ isCircle }) => (isCircle ? "76px" : "132px")};
  max-height: ${({ isCircle }) => (isCircle ? "76px" : "132px")};
  background-color: ${({ theme }) => theme.colors.gray4};
  border-radius: ${({ isCircle }) => (isCircle ? "50%" : "10px")};
  object-fit: cover;
  overflow: hidden;
  ${mq(768)} {
    min-width: ${({ isCircle }) => (isCircle ? "72px" : "108px")};
    max-width: ${({ isCircle }) => (isCircle ? "72px" : "108px")};
    min-height: ${({ isCircle }) => (isCircle ? "72px" : "80px")};
    max-height: ${({ isCircle }) => (isCircle ? "72px" : "80px")};
    border-radius: ${({ isCircle }) => (isCircle ? "50%" : "7.2px")};
  }
`;

export const ListOfSubjectsWrapper = styled.div<{
  readonly listView?: boolean;
}>`
  ${({ listView }) =>
    listView
      ? css`
          min-width: 177px;
          max-width: 177px;
          min-height: 132px;
          max-height: 132px;
          background-color: ${({ theme }) => theme.colors.gray6};
        `
      : css`
          width: 100%;
          max-width: 302px;
          height: 100%;
          max-height: 142px;
          /* background-color: ${({ theme }) => theme.colors.gray6}; */
        `}
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  /* ${mq(768)} {
    min-width: 108px;
    max-width: 108px;
    min-height: 80px;
    max-height: 80px;
    border-radius: 7.2px;
  } */
`;
export const PackageThumbnailOverlay = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  min-width: 177px;
  max-width: 177px;
  min-height: 132px;
  max-height: 132px;
  background-color: transparent;
  border-radius: 10px;
  z-index: 50;
  ${mq(768)} {
    min-width: 108px;
    max-width: 108px;
    min-height: 80px;
    max-height: 80px;
    border-radius: 7.2px;
  }
`;

export const PackageCardImage = styled.div<{
  readonly index?: any;
  readonly length?: any;
}>`
  position: absolute;
  transform: translate(-50%, -50%);
  font-size: 7.5px;
  ${({ index, length }) =>
    length === 2 && index === 1
      ? css`
          top: 55%;
          left: 55%;
          max-width: 70%;
          min-width: 70%;
          z-index: 10;
        `
      : length === 2 && index === 2
        ? css`
            top: 44%;
            left: 46%;
            max-width: 70%;
            min-width: 70%;
            z-index: 9;
          `
        : length === 3 && index === 1
          ? css`
              top: 50%;
              left: 50%;
              max-width: 66%;
              min-width: 66%;
              z-index: 10;
            `
          : length === 3 && index === 2
            ? css`
                top: 50%;
                left: 63%;
                max-width: 57%;
                min-width: 57%;
                z-index: 9;
              `
            : length === 3 && index === 3
              ? css`
                  top: 50%;
                  left: 37%;
                  max-width: 57%;
                  min-width: 57%;
                  z-index: 8;
                `
              : length === 4 && index === 1
                ? css`
                    max-width: 65%;
                    min-width: 65%;
                    top: 50%;
                    left: 61%;
                    z-index: 10;
                  `
                : length === 4 && index === 2
                  ? css`
                      max-width: 60%;
                      min-width: 60%;
                      top: 50%;
                      left: 51%;
                      z-index: 9;
                    `
                  : length === 4 && index === 3
                    ? css`
                        top: 50%;
                        left: 41%;
                        max-width: 55%;
                        min-width: 55%;
                        z-index: 8;
                      `
                    : length === 4 && index === 4
                      ? css`
                          top: 50%;
                          left: 32%;
                          max-width: 50%;
                          min-width: 50%;
                          z-index: 7;
                        `
                      : length === 5 && index === 1
                        ? css`
                            top: 50%;
                            left: 50%;
                            max-width: 64%;
                            min-width: 64%;
                            z-index: 10;
                          `
                        : length === 5 && index === 2
                          ? css`
                              top: 50%;
                              left: 59%;
                              max-width: 58%;
                              min-width: 58%;
                              z-index: 9;
                            `
                          : length === 5 && index === 3
                            ? css`
                                top: 50%;
                                left: 41%;
                                max-width: 58%;
                                min-width: 58%;
                                z-index: 8;
                              `
                            : length === 5 && index === 4
                              ? css`
                                  top: 50%;
                                  left: 32%;
                                  max-width: 51%;
                                  min-width: 51%;
                                  z-index: 7;
                                `
                              : length === 5 && index === 5
                                ? css`
                                    top: 50%;
                                    left: 68%;
                                    max-width: 51%;
                                    min-width: 51%;
                                    z-index: 6;
                                  `
                                : length === 6 && index === 1
                                  ? css`
                                      top: 50%;
                                      left: 61%;
                                      max-width: 65%;
                                      min-width: 65%;
                                      z-index: 10;
                                    `
                                  : length === 6 && index === 2
                                    ? css`
                                        top: 50%;
                                        left: 53%;
                                        max-width: 60%;
                                        min-width: 60%;
                                        z-index: 9;
                                      `
                                    : length === 6 && index === 3
                                      ? css`
                                          top: 50%;
                                          left: 46%;
                                          max-width: 55%;
                                          min-width: 55%;
                                          z-index: 8;
                                        `
                                      : length === 6 && index === 4
                                        ? css`
                                            top: 50%;
                                            left: 39%;
                                            max-width: 50%;
                                            min-width: 50%;
                                            z-index: 7;
                                          `
                                        : length === 6 && index === 5
                                          ? css`
                                              top: 50%;
                                              left: 33%;
                                              max-width: 45%;
                                              min-width: 45%;
                                              z-index: 6;
                                            `
                                          : length === 6 && index === 6
                                            ? css`
                                                top: 50%;
                                                left: 26%;
                                                max-width: 40%;
                                                min-width: 40%;
                                                z-index: 5;
                                              `
                                            : length === 7 && index === 1
                                              ? css`
                                                  top: 50%;
                                                  left: 50%;
                                                  max-width: 62%;
                                                  min-width: 62%;
                                                  z-index: 10;
                                                `
                                              : length === 7 && index === 2
                                                ? css`
                                                    top: 50%;
                                                    left: 58%;
                                                    max-width: 55%;
                                                    min-width: 55%;
                                                    z-index: 9;
                                                  `
                                                : length === 7 && index === 3
                                                  ? css`
                                                      top: 50%;
                                                      left: 42%;
                                                      max-width: 55%;
                                                      min-width: 55%;
                                                      z-index: 8;
                                                    `
                                                  : length === 7 && index === 4
                                                    ? css`
                                                        top: 50%;
                                                        left: 34%;
                                                        max-width: 47%;
                                                        min-width: 47%;
                                                        z-index: 7;
                                                      `
                                                    : length === 7 && index === 5
                                                      ? css`
                                                          top: 50%;
                                                          left: 66%;
                                                          max-width: 47%;
                                                          min-width: 47%;
                                                          z-index: 6;
                                                        `
                                                      : length === 7 && index === 6
                                                        ? css`
                                                            top: 50%;
                                                            left: 73%;
                                                            max-width: 40%;
                                                            min-width: 40%;
                                                            z-index: 5;
                                                          `
                                                        : length === 7 && index === 7
                                                          ? css`
                                                              top: 50%;
                                                              left: 27%;
                                                              max-width: 40%;
                                                              min-width: 40%;
                                                              z-index: 4;
                                                            `
                                                          : length === 8 && index === 1
                                                            ? css`
                                                                top: 50%;
                                                                left: 64%;
                                                                max-width: 65%;
                                                                min-width: 65%;
                                                                z-index: 10;
                                                              `
                                                            : length === 8 && index === 2
                                                              ? css`
                                                                  top: 50%;
                                                                  left: 57%;
                                                                  max-width: 60%;
                                                                  min-width: 60%;
                                                                  z-index: 9;
                                                                `
                                                              : length === 8 && index === 3
                                                                ? css`
                                                                    top: 50%;
                                                                    left: 50%;
                                                                    max-width: 55%;
                                                                    min-width: 55%;
                                                                    z-index: 8;
                                                                  `
                                                                : length === 8 && index === 4
                                                                  ? css`
                                                                      top: 50%;
                                                                      left: 43%;
                                                                      max-width: 50%;
                                                                      min-width: 50%;
                                                                      z-index: 7;
                                                                    `
                                                                  : length === 8 && index === 5
                                                                    ? css`
                                                                        top: 50%;
                                                                        left: 37%;
                                                                        max-width: 45%;
                                                                        min-width: 45%;
                                                                        z-index: 6;
                                                                      `
                                                                    : length === 8 && index === 6
                                                                      ? css`
                                                                          top: 50%;
                                                                          left: 31%;
                                                                          max-width: 40%;
                                                                          min-width: 40%;
                                                                          z-index: 5;
                                                                        `
                                                                      : length === 8 && index === 7
                                                                        ? css`
                                                                            top: 50%;
                                                                            left: 25%;
                                                                            max-width: 35%;
                                                                            min-width: 35%;
                                                                            z-index: 4;
                                                                          `
                                                                        : length === 8 && index === 8
                                                                          ? css`
                                                                              top: 50%;
                                                                              left: 20%;
                                                                              max-width: 30%;
                                                                              min-width: 30%;
                                                                              z-index: 3;
                                                                            `
                                                                          : length === 9 && index === 1
                                                                            ? css`
                                                                                top: 50%;
                                                                                left: 50%;
                                                                                max-width: 60%;
                                                                                min-width: 60%;
                                                                                z-index: 10;
                                                                              `
                                                                            : length === 9 && index === 2
                                                                              ? css`
                                                                                  top: 50%;
                                                                                  left: 58%;
                                                                                  max-width: 53%;
                                                                                  min-width: 53%;
                                                                                  z-index: 9;
                                                                                `
                                                                              : length === 9 && index === 3
                                                                                ? css`
                                                                                    top: 50%;
                                                                                    left: 42%;
                                                                                    max-width: 53%;
                                                                                    min-width: 53%;
                                                                                    z-index: 8;
                                                                                  `
                                                                                : length === 9 && index === 4
                                                                                  ? css`
                                                                                      top: 50%;
                                                                                      left: 65%;
                                                                                      max-width: 48%;
                                                                                      min-width: 48%;
                                                                                      z-index: 7;
                                                                                    `
                                                                                  : length === 9 && index === 5
                                                                                    ? css`
                                                                                        top: 50%;
                                                                                        left: 35%;
                                                                                        max-width: 48%;
                                                                                        min-width: 48%;
                                                                                        z-index: 6;
                                                                                      `
                                                                                    : length === 9 && index === 6
                                                                                      ? css`
                                                                                          top: 50%;
                                                                                          left: 28%;
                                                                                          max-width: 42%;
                                                                                          min-width: 42%;
                                                                                          z-index: 5;
                                                                                        `
                                                                                      : length === 9 && index === 7
                                                                                        ? css`
                                                                                            top: 50%;
                                                                                            left: 72%;
                                                                                            max-width: 42%;
                                                                                            min-width: 42%;
                                                                                            z-index: 4;
                                                                                          `
                                                                                        : length === 9 && index === 8
                                                                                          ? css`
                                                                                              top: 50%;
                                                                                              left: 78%;
                                                                                              max-width: 36%;
                                                                                              min-width: 36%;
                                                                                              z-index: 3;
                                                                                            `
                                                                                          : length === 9 && index === 9
                                                                                            ? css`
                                                                                                top: 50%;
                                                                                                left: 22%;
                                                                                                max-width: 36%;
                                                                                                min-width: 36%;
                                                                                                z-index: 2;
                                                                                              `
                                                                                            : css``}
`;

export const SubjectCardData = styled.div``;

export const LiveDataAndTime = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

export const LiveDetailsTittle = styled.h2`
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.body14};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray2};
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const SubjectCardTitle = styled.h2`
  font-weight: ${({ theme }) => theme.fontWeight.font600};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.nero};
  margin-bottom: 4px;
`;

export const BreadCrumbWrapper = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.body14};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray1};
  margin-bottom: 4px;
  display: flex;
  align-items: center;
`;

export const CardSubTitle = styled.h4`
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.body14};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray2};
`;

export const GrayPoint = styled.div`
  max-width: 5px;
  min-width: 5px;
  max-height: 5px;
  min-height: 5px;
  background-color: ${({ theme }) => theme.colors.gray2};
  border-radius: 50%;
  margin: 0 8px;
`;

export const ArrowRights = styled.img`
  width: 10px;
  ${mq(768)} {
    display: none;
  }
  &:lang(ar) {
    transform: scale(-1, 1);
  }
`;
