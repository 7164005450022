import styled from "styled-components";

export const ButtonContainer = styled.div<{
  readonly textColor?: string;
  readonly backgroundColor?: string;
  readonly fill?: boolean;
  readonly hoverChange?: boolean;
  readonly disabled?: boolean;
  readonly size?: string;
}>`
  z-index: 1;
  ${({ theme, disabled }) =>
    disabled &&
    `
    a,
    button {
      pointer-events: none;
      color: ${theme.colors.white};
      background: ${theme.colors.gray4} !important;
      cursor: not-allowed;
    }
  `}
  a,
  button {
    display: flex;
    border: 0;
    cursor: pointer;
    font-weight: ${({ theme }) => theme.fontWeight.font500};
    width: 100%;
    justify-content: center;
    color: ${({ theme, textColor }) => (textColor ? theme.colors[textColor] : theme.colors.white)};
    background: ${({ theme, backgroundColor }) =>
      backgroundColor ? theme.colors[backgroundColor] : theme.colors.seagule};
    border-radius: ${({ theme }) => theme.buttons.borderRadius};
    font-size: ${({ theme, size }) => (size ? theme.fontsizes[size] : theme.buttons.fontSize)};
    padding: ${({ theme }) => theme.buttons.padding};
    transition: all 0.3s ease-in-out;
    &.not-fill {
      border: 1.5px solid ${({ theme }) => theme.colors.seagule};
      background: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.seagule};
      &:hover {
        border-color: ${({ theme }) => theme.colors.seagule};
        background: inherit;
      }
      &[disabled] {
        color: ${({ theme }) => theme.colors.gray4};
        background: inherit;
      }
    }
    &:hover {
      background: ${({ theme, hoverChange, backgroundColor }) =>
        hoverChange ? theme.colors.seaguleDarker : theme.colors[backgroundColor]};
      transition: all 0.3s ease-in-out;
    }
  }
`;

export const ButtonContainerSocial = styled(ButtonContainer)<{
  readonly textColor?: string;
  readonly backgroundColor?: string;
  readonly fill?: boolean;
  readonly hoverChange?: boolean;
  readonly disabled?: boolean;
}>`
  ${({ theme, disabled }) =>
    disabled &&
    `
      a,
      button {
        pointer-events: none;
        color: ${theme.colors.white};
        background: ${theme.colors.gray4} !important;
        cursor: not-allowed;
      }
  `}
  a,
  button {
    justify-content: inherit;
    display: flex;
    align-items: center;
    padding: ${({ theme }) => theme.colors.social.padding};
    background: ${({ theme, backgroundColor }) =>
      backgroundColor ? theme.colors.social[backgroundColor] : theme.colors.social.phone};
    color: ${({ theme, textColor }) => (textColor ? theme.colors.social[textColor] : theme.colors.white)};
    span {
      margin: 0 12px;
    }
  }
`;

export const ButtonLink = styled.a<{
  readonly textAlign?: string;
  readonly dimmed?: boolean;
  readonly bold?: boolean;
  readonly underline?: boolean;
}>`
  width: 100%;
  display: block;
  text-align: ${({ textAlign }) => textAlign || "unset"};
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontsizes.body14};
  font-weight: ${({ bold }) => (bold ? "600" : "400")};
  color: ${({ theme, dimmed }) => (dimmed ? theme.colors.seagule : theme.colors.gray2)};
  text-decoration: ${({ underline }) => (underline ? "underline" : "unset")};
  text-decoration-color: ${({ theme, dimmed }) => (dimmed ? theme.colors.seagule : theme.colors.gray2)};
  background: none;
  border: 0;
  &[disabled] {
    color: ${({ theme }) => theme.colors.gray4};
    background: inherit;
    text-decoration-color: ${({ theme }) => theme.colors.gray4};
  }
`;
