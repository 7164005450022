import { useRouter } from "next/router";
import { useEffect } from "react";
import { boot as bootIntercom, load as loadIntercom, update as updateIntercom } from "../../lib/IntercomBoot";

export const IntercomProvider = ({ children }) => {
  const router = useRouter();

  if (typeof window !== "undefined") {
    loadIntercom();
    bootIntercom();
  }

  useEffect(() => {
    const handleRouteChange = (url) => {
      if (typeof window !== "undefined") {
        updateIntercom();
      }
    };

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router.events]);

  return children;
};
