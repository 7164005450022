import { CommonButton } from "@Components/common/CommonButton";
import { mq } from "@Utils/StyleUtil";
import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 16px 0 16px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors.gray7};
  margin-bottom: 32px;
  ${mq(1024)} {
    padding: 16px 0;
  }
  ${mq(992)} {
    background-color: transparent;
  }
`;

export const Details = styled.div`
  width: 100%;
  border-radius: 10.5px;
  display: flex;
  align-items: center;
  ${mq(992)} {
    border: 1px solid ${({ theme }) => theme.colors.gray5_old};
    padding: 16px 22.25px 16px 16px;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  ${mq(992)} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const IconWrapper = styled.div`
  max-width: 56px;
  min-width: 56px;
  max-height: 56px;
  min-height: 56px;
  border-radius: 10.5px;
  margin-right: 16px;
  background: ${({ theme }) => theme.colors.purple};
  padding: 15.22px 13.32px 14.53px 14.11px;
  position: relative;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  ${mq(992)} {
    max-width: 32px;
    min-width: 32px;
    max-height: 32px;
    min-height: 32px;
    border-radius: 6px;
    padding: 8.7px 7.61px 8.3px 8.06px;
    margin-bottom: 12px;
  }
`;

export const Div = styled.div``;

export const Title = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  color: ${({ theme }) => theme.colors.nero};
  margin-bottom: 4px;
  line-height: 28px;
`;

export const Description = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.body14};
  color: ${({ theme }) => theme.colors.gray2};
  ${mq(576)} {
    max-width: 263px;
  }
`;

export const Actions = styled.div`
  cursor: pointer;
  margin-left: auto;
  min-width: 7.5px;
  img {
    transform: none /*rtl: scale(-1, 1) */;
  }
`;

export const UpdateButton = styled(CommonButton)`
  min-width: 220px;
  height: 56px;
  font-size: ${({ theme }) => theme.fontsizes.body18};
  font-weight: 400;
  text-transform: capitalize;
`;

export const BlurBgWrapper = styled.div`
  & > div {
    z-index: 101;
  }
`;

export const BreadcrumbsHomeWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.gray6};
  padding: 8px;
  border-radius: 4px;
  margin: 24px 0 24px 10px;
  ${mq(1024)} {
    margin: 24px 0;
  }
  display: inline-block;
  cursor: pointer;
  position: relative;
`;

export const BreadcrumbsArrow = styled.img`
  position: absolute;
  width: 12px;
  height: 6px;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
`;
