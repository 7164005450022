import styled from "styled-components";
import { LandingBGColors } from "types/common";

export const BoxWrapper = styled.div<{ readonly commonBG?: LandingBGColors }>`
  transition: 0.9s ease background-color;
  border-radius: 28px;
  z-index: 9;
  overflow: hidden;
  background: ${({ theme, commonBG }) => theme.colors[commonBG]};
`;
