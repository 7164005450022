import { createModel } from "@rematch/core";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { RootModel } from ".";
import { GeneralIP_Info } from "./../../types/IPRegistert.d";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

type BannerModel = {
  isBannerVisible: boolean;
  startDate: string;
  endDate: string;
};

const Banner = createModel<RootModel>()({
  name: "banner",
  state: {
    isBannerVisible: false,
    startDate: "2024-02-20T00:00:00+03:00",
    endDate: "2024-02-29T23:59:00+03:00",
  } as BannerModel,
  reducers: {
    validateBannerVisibility: (state, ipInfo: GeneralIP_Info) => {
      if (
        ipInfo &&
        ipInfo.country_code === "sa" &&
        dayjs(new Date()).isSameOrAfter(dayjs(state.startDate)) &&
        dayjs(new Date()).isSameOrBefore(dayjs(state.endDate))
      ) {
        state.isBannerVisible = true;
      }
    },
    setIsBannerVisible: (state, payload: boolean) => {
      state.isBannerVisible = payload;
    },
  },
  effects: (dispatch) => ({
    async _checkNavbarBanner(ipInfo: GeneralIP_Info) {
      dispatch.banner.validateBannerVisibility(ipInfo);
    },
    async _setIsBannerVisible(payload: boolean) {
      dispatch.banner.setIsBannerVisible(payload);
    },
  }),
});

export default Banner;
