type Props = {
  color?: string;
  width?: string;
  height?: string;
  margin?: string;
  rotated?: boolean;
};

const NavArrowRightSvgIcon = ({ color, width, height, margin, rotated }: Props) => {
  return (
    <svg
      style={{ margin: margin ? margin : "0", transform: rotated && "rotate(180deg)" }}
      width={width ? width : "18"}
      height={height ? height : "14"}
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4558 7.00236C17.4558 6.77146 17.3647 6.5679 17.1824 6.39168L11.1394 0.366943C11.0422 0.269721 10.9419 0.199843 10.8386 0.157308C10.7414 0.114773 10.6381 0.0935059 10.5287 0.0935059C10.3039 0.0935059 10.1155 0.169461 9.96362 0.32137C9.81171 0.467204 9.73576 0.652534 9.73576 0.87736C9.73576 0.986735 9.75399 1.09307 9.79045 1.19637C9.82691 1.29359 9.88159 1.37866 9.95451 1.45158L11.9962 3.5297L15.6147 6.82918L15.797 6.37345L12.8621 6.19116H1.33211C1.09513 6.19116 0.900689 6.26712 0.748779 6.41903C0.602946 6.57094 0.530029 6.76538 0.530029 7.00236C0.530029 7.23934 0.602946 7.43378 0.748779 7.58569C0.900689 7.7376 1.09513 7.81356 1.33211 7.81356H12.8621L15.797 7.63127L15.6147 7.18465L11.9962 10.475L9.95451 12.5531C9.88159 12.6261 9.82691 12.7142 9.79045 12.8175C9.75399 12.9147 9.73576 13.018 9.73576 13.1274C9.73576 13.3522 9.81171 13.5375 9.96362 13.6834C10.1155 13.8353 10.3039 13.9112 10.5287 13.9112C10.7475 13.9112 10.945 13.8261 11.1212 13.656L17.1824 7.61304C17.3647 7.43682 17.4558 7.23326 17.4558 7.00236Z"
        fill={color ? color : "#ffffff"}
      />
    </svg>
  );
};

export default NavArrowRightSvgIcon;
