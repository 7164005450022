// @ts-nocheck
import Link from "@Components/common/Link";
import { mq, rgba } from "@Utils/StyleUtil";
import styled, { css } from "styled-components";

export const SubjectsWrapper = styled.div`
  width: 100%;
  height: 192px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  background-color: #fff;
  /* padding: 27px 12px; */
`;
export const Arrow = styled.img`
  /* display: none; */
  max-width: 7.64px;
  min-width: 7.64px;
  max-height: 13.56px;
  min-height: 13.56px;
  transform: none /*rtl: scale(-1, 1) */;
  ${mq(992)} {
    display: block;
  }
`;
export const PackageDetailsWrapper = styled.div<{ readonly isFree?: boolean; readonly inProfilePage?: boolean }>`
  background: ${({ theme }) => theme.colors.gray6};
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: ${({ inProfilePage }) => (inProfilePage ? "flex-start" : "center")};
`;

export const PackageName = styled.p<{
  readonly listView?: boolean;
}>`
  font-weight: ${({ theme }) => theme.fontWeight.font600};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  color: ${({ theme }) => theme.colors.nero};
  line-height: 28px;
  max-width: ${({ listView }) => (listView ? "unset" : "316px")};
  overflow-x: ${({ listView }) => (listView ? "unset" : "hidden")};
  text-overflow: ellipsis;
  white-space: ${({ listView }) => (listView ? "unset" : "nowrap")};
`;

export const PackageDescription = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.body14};
  color: ${({ theme }) => theme.colors.gray3};
  line-height: 24px;
  align-self: self-start;
  margin-bottom: 12px;
  span {
    color: ${({ theme }) => theme.colors.nero};
  }
`;

export const OldPrice = styled.p<{ readonly lineThrow?: boolean }>`
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.body14};
  color: ${({ theme }) => theme.colors.gray2};
  line-height: 24px;
  margin-bottom: 4px;
  position: relative;
  display: inline-block;
  padding: 0 1px;
  &::after {
    position: absolute;
    content: "";
    top: 10px;
    left: 0;
    width: 0%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.gray2};
  }
  ${({ lineThrow }) =>
    lineThrow &&
    css`
      &::after {
        width: 100%;
      }
    `}
`;
export const OptionsList = styled.div<{
  readonly listView?: boolean;
}>`
  display: flex;
  width: 100%;
  margin-top: ${({ listView }) => (listView ? 4 : 0)}px;
`;
export const PackageOption = styled.div<{
  readonly listView?: boolean;
  readonly isSubscribed?: boolean;
  readonly length?: number;
}>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: ${({ listView }) => (listView ? 24 : 28)}px;
  max-height: ${({ listView }) => (listView ? 24 : 28)}px;
  width: ${({ length, isSubscribed }) => (length === 3 ? 30 : length === 2 ? 50 : !isSubscribed && 100)}%;
  padding: ${({ listView }) => (listView ? "0 8px" : "0 12px")};
  margin-bottom: ${({ listView }) => (listView ? 8 : 0)}px;
  background-color: ${({ theme, listView, isSubscribed }) =>
    isSubscribed
      ? listView
        ? rgba(theme.colors.green, 0.1)
        : theme.colors.white
      : listView
        ? theme.colors.seagullNeutrals
        : theme.colors.white};
  color: ${({ theme, listView, isSubscribed }) =>
    isSubscribed ? theme.colors.green : listView ? theme.colors.seagule : theme.colors.nero};
  font-size: ${({ theme }) => theme.fontsizes.body14};
  border-radius: ${({ isSubscribed }) => (isSubscribed ? 6 : 999)}px;
  margin-right: 4px;
  border: 1px solid ${({ theme, listView }) => (listView ? theme.colors.seagullNeutrals : theme.colors.gray5_old)};
  white-space: nowrap;
  ${({ listView, isSubscribed }) =>
    !listView &&
    !isSubscribed &&
    css`
      flex: 1;
    `}
  &:last-of-type {
    margin-right: 0;
  }
`;
export const PackageOptionContent = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const NewPrice = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.body14};
  color: ${({ theme }) => theme.colors.seagule};
  background-color: ${({ theme }) => theme.colors.seagullNeutrals};
  line-height: 28px;
  border-radius: 40px;
  padding: 0 12px;
`;

export const DiscountBadge = styled.p`
  padding: 0 8px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.seagule};
  line-height: 24px;
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.body14};
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 6px;
`;

export const Div = styled.div<{
  readonly horizontal?: boolean;
  readonly packageHasOptions?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &:last-of-type {
    align-items: flex-start;
    justify-content: center;
  }
  ${({ packageHasOptions }) =>
    packageHasOptions &&
    css`
      width: 100%;
      align-items: flex-start !important;
    `}
  ${({ horizontal }) =>
    horizontal &&
    css`
      flex-direction: row;
      align-items: center;
      ${OldPrice} {
        margin: 0 8px 0 0;
        line-height: 28px;
        &::after {
          top: 50%;
          transform: translateY(-50%);
        }
      }
    `}
`;

export const FreePrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 28px;
  border-radius: 40px;
  color: ${({ theme }) => theme.colors.seagule};
  background-color: ${({ theme }) => theme.colors.seagullNeutrals};
`;
// list of subjects

export const ListOfSubjects = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
`;

export const SubjectListItem = styled.div<{
  readonly index: any;
  readonly length: any;
  readonly listView: any;
}>`
  /* width: 189.82px; */
  width: ${({ listView }) => (listView ? "112.82px" : "189.82px")};
  top: 50%;
  transform: ${({ length, index }) => length && `translateY(-50%) scale(${`0.${10 - index}`})`};
  right: ${({ index, listView }) => index && index * (listView ? 14 : 30)}px;
  position: absolute;
  z-index: ${({ index, length }) => index && length - index};
  font-size: 8px;
  & > div {
    box-shadow: none;
  }
  ${({ length, index, listView }) =>
    length === 2
      ? css`
          /* width: 153.18px; */
          width: ${({ listView }) => (listView ? "112px" : "153.18px")};
          &:first-of-type {
            /* top: 52.41px; */
            top: ${({ listView }) => (listView ? "38px" : "52.41px")};
            transform: none;
            /* right: 87.55px; */
            right: ${({ listView }) => (listView ? "19.09px" : "87.55px")};
          }
          &:last-of-type {
            top: 25px;
            top: ${({ listView }) => (listView ? "16px" : "25px")};
            transform: none;
            /* right: 105.43px; */
            right: ${({ listView }) => (listView ? "36px" : "105.43px")};
          }
        `
      : length % 2 != 0
        ? css`
            /* width: 189.82px; */
            width: ${({ listView }) => (listView ? "112.82px" : "189.82px")};
            &:nth-of-type(odd) {
              top: 50%;
              transform: ${({ length }) => length && `translateY(-50%) scale(${1 - `0.${1}`})`};
              right: ${({ length }) => length && length * 15}px;
            }
            &:nth-of-type(even) {
              top: 50%;
              transform: ${({ length }) => length && `translateY(-50%) scale(${1 - `0.${1}`})`};
              left: ${({ length }) => length && length * 15}px;
            }
            &:first-of-type {
              left: 50%;
              transform: translate(-50%, -50%);
            }
          `
        : index && css``}
`;
export const PackageWrapperLink = styled(Link)``;
export const Wrapper = styled.div<{
  readonly listView?: boolean;
}>`
  width: 346px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
  ${({ listView }) =>
    listView &&
    css`
      display: flex;
      width: 100%;
      height: fit-content;
      box-shadow: none;
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray6};
      border-radius: 0;
      ${SubjectsWrapper} {
        background-color: ${({ theme }) => theme.colors.gray7};
        border-radius: 10px;
        min-width: 176px;
        width: 176px;
        height: 132px;
        margin-right: 16px;
      }
      ${SubjectListItem} {
        /* width: 112.82px; */
        font-size: 6px;
      }
      ${PackageDetailsWrapper} {
        background-color: transparent;
        width: 100%;
        padding: 0;
        ${Div} {
          align-items: flex-start;
        }
        img {
          margin: auto 8px auto 0;
        }
      }
    `}
`;

export const PackageBreadcrumb = styled.h2`
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.body18};
  color: ${({ theme }) => theme.colors.nero};
  margin: 5px 0;
  display: block;
  img {
    margin-right: 0 !important;
  }
`;
