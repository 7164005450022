type Props = {
  margin?: string;
  color?: string;
  width?: string;
  height?: string;
};

const LightArrowLeftSvgIcon = ({ margin, color, width, height }: Props) => {
  return (
    <svg
      style={{ margin: margin ? margin : "0" }}
      width={width ? width : "8"}
      height={height ? height : "13"}
      viewBox="0 0 8 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.785156 6.35716C0.785156 6.53755 0.858955 6.70155 0.99015 6.84095L6.05756 11.8756C6.18056 12.0068 6.35275 12.0723 6.54135 12.0723C6.92673 12.0723 7.21373 11.7854 7.21373 11.4C7.21373 11.2114 7.13992 11.0474 7.01693 10.9162L2.4415 6.35716L7.01693 1.79813C7.13992 1.66693 7.21373 1.49473 7.21373 1.31434C7.21373 0.92896 6.92673 0.641968 6.54135 0.641968C6.35275 0.641968 6.18056 0.707562 6.05756 0.838766L0.99015 5.87337C0.858955 6.01277 0.785156 6.17677 0.785156 6.35716Z"
        fill={color ? color : "#ABABAB"}
      />
    </svg>
  );
};

export default LightArrowLeftSvgIcon;
