import { useRouter } from "next/router"; // Correctly import for Next.js App Router
import { useEffect } from "react";

// Define a type for the query parameters, which will be an object with string keys and values
interface QueryParams {
  [key: string]: string;
}

// Define a type for the session data to be stored in sessionStorage
interface SessionData {
  queryParams: QueryParams;
  referrer: string | null;
}

// List of query parameters to track
const TRACKED_PARAMS = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
  "gclid",
  "gbraid",
  "wbraid",
  "dclid",
  "fbclid",
  "ttclid",
  "twclid",
  "sccid",
];

const useQueryParamsStorage = (): void => {
  const router = useRouter(); // Router from Next.js App Router

  useEffect(() => {
    if (typeof window !== "undefined") {
      // Get the current query parameters from the URL
      const queryParams = Object.fromEntries(new URLSearchParams(window.location.search));

      // Filter only the tracked query parameters
      const filteredParams: QueryParams = Object.keys(queryParams)
        .filter((key) => TRACKED_PARAMS.includes(key.toLowerCase()))
        .reduce((acc: QueryParams, key) => {
          acc[key] = queryParams[key];
          return acc;
        }, {});

      // Check if there are any tracked params in the URL
      if (Object.keys(filteredParams).length > 0) {
        // Get the referrer URL or null if there's none
        const referrer: string | null = document.referrer || null;

        // Build the session data object to store in sessionStorage
        const utmQueries: SessionData = {
          queryParams: filteredParams,
          referrer,
        };

        // Store the session data in sessionStorage
        sessionStorage.setItem("utmQueries", JSON.stringify(utmQueries));
      }
    }
  }, [router]);
};

export default useQueryParamsStorage;
