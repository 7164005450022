import { SubjectSpinner } from "@Components/SubjectPage/SubjectPage.styled";
import { CircularProgress } from "@material-ui/core";
import Box from "@mui/material/Box";
import React, { ReactElement } from "react";

interface Props {}

export default function FullPageLoader({}: Props): ReactElement {
  return (
    <SubjectSpinner>
      <Box className="box">
        <CircularProgress className="spinner_circle" />
      </Box>
    </SubjectSpinner>
  );
}
