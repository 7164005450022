// @ts-nocheck
/* eslint-disable */

import { useAuth } from "@Context/Auth.context";

export const APP_ID = process.env.NEXT_PUBLIC_INTERCOM_ID;

// prettier-ignore
export const load = () => {
  (function(){let w=window;let ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{let d=document;let i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;let l=function(){let s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;let x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
}

// Initializes Intercom
export const boot = (options = {}) => {
  const { userToken, userData } = useAuth();
  return (
    window &&
    window.Intercom &&
    window.Intercom("boot", {
      app_id: APP_ID,
      api_base: "https://api-iam.intercom.io/",
      user_id: userToken && userData?.id,
      name: userToken && userData?.name,
      email: userToken && userData?.email,
      phone: userToken && userData?.phone,
      created_at: userToken && Date.now(),
      custom_launcher_selector: ".open_intercom",
    })
  );
};

export const update = () => {
  window && window.Intercom && window.Intercom("update");
};
