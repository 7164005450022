import { useSelector } from "react-redux";
import { RootState } from "store";

export function useThemeType() {
  const { themeType } = useSelector((state: RootState) => state.themeType);

  return {
    themeType,
  };
}
