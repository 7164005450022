import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "store";

export function useAuth() {
  const { userToken, userData, localIpInfo, loginModal } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch<Dispatch>();

  return {
    userToken,
    userData,
    localIpInfo,
    setUserData: dispatch.user.setUserData,
    isUserFetching: Boolean(userToken) && userData === undefined,
    updateToken: dispatch.user.setToken,
    loginModal,
    setLoginModal: dispatch.user.setLoginModal,
    logOut: dispatch.user._logout,
    fetchUserData: dispatch.user._fetchUserData.bind(userToken),
    socialHelper: dispatch.user._socialHelper,
  };
}
