import { getTextColor } from "@Utils/DetectBGColor";
import styled from "styled-components";

export const PageWrapper = styled.div<{
  readonly bgColor?: string;
}>`
  background-color: ${({ theme }) => theme.colors.white};
  padding-bottom: 200px;
  color: ${({ theme, bgColor }) => theme.colors[getTextColor(theme.colors[bgColor])]};
`;
export const Wrapper = styled.main`
  min-height: calc(100vh - 72px); /* 72px for fixed navbar height */
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
`;
export const ProfileBackground = styled.img`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  @media only screen and (min-width: 1199.98px) {
    object-position: center center;
  }
`;
