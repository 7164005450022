// @ts-nocheck
/* eslint-disable */
import { stripButton } from "@Utils/StyleUtil";
import styled, { css } from "styled-components";

export const CommonButton = styled.button<{
  readonly variant: "default" | "white-filled" | "rounded" | "fullWidth" | "lightseagule";
  readonly disabled?: boolean;
  readonly isGaped?: boolean;
}>`
  ${stripButton};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.font500};
  line-height: 24px;
  padding: 14px;
  transition:
    0.2s ease background-color,
    0.2s ease box-shadow;
  ${({ isGaped }) =>
    isGaped &&
    css`
      gap: 10px;
    `}
  ${({ variant, theme }) =>
    variant === "default"
      ? css`
          background-color: ${theme.colors.seagule};
          box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
          color: ${theme.colors.white};
          &:hover {
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
            background-color: ${theme.colors.seagule_hover};
          }
          &:active {
            background: ${theme.colors.seagule_active};
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.12);
          }
          &:disabled,
          &[disabled] {
            cursor: not-allowed;
            background-color: ${theme.colors.gray8};
          }
        `
      : variant === "white-filled"
        ? css`
            background: ${theme.colors.white};
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.07);
            color: ${theme.colors.darkSeagule};
            &:hover {
              box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
            }
            &:active {
              box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.07);
            }
            &:disabled,
            &[disabled] {
              cursor: not-allowed;
              color: ${theme.colors.gray8};
            }
          `
        : variant === "rounded"
          ? css`
              background-color: ${theme.colors.seagule};
              box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
              color: ${theme.colors.white};
              border-radius: 40px;
              padding: 32px 24px;
              &:hover {
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
                background-color: ${theme.colors.seagule_hover};
              }
              &:active {
                background: ${theme.colors.seagule_active};
                box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.12);
              }
              &:disabled,
              &[disabled] {
                cursor: not-allowed;
                background-color: ${theme.colors.gray8};
              }
            `
          : variant === "fullWidth"
            ? css`
                font-weight: 700;
                font-size: 16px;
                line-height: 28px;
                width: 100%;
                background-color: ${theme.colors.seagule};
                box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
                color: ${theme.colors.white};
                &:hover {
                  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
                  background-color: ${theme.colors.seagule_hover};
                }
                &:active {
                  background: ${theme.colors.seagule_active};
                  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.12);
                }
                &:disabled,
                &[disabled] {
                  cursor: not-allowed;
                  background-color: ${theme.colors.gray8};
                }
              `
            : variant === "lightseagule"
              ? css`
                  background: ${({ theme }) => theme.colors.seagullNeutrals};
                  color: ${({ theme }) => theme.colors.seagule};
                  &:hover {
                    background: ${({ theme }) => theme.colors.seagullNeutrals};
                    color: ${({ theme }) => theme.colors.seagule};
                    opacity: 0.9;
                  }
                `
              : css``}
`;
