export const SOCIAL = "SOCIAL";
export const PHONE = "PHONE";
export const LOGIN = "LOGIN";
export const SUCCESS = "SUCCESS";
export const VERIFICATION_SUCCESS = "VERIFICATION-SUCCESS";
export const VERIFICATION = "VERIFICATION";
export const VERIFICATION_OTP = "VERIFICATION-OTP";
export const SET_NEWPASSWORD = "SET-NEWPASSWORD";
export const SET_NEWPASSWORD_SUCCESS = "SET-NEWPASSWORD-SUCCESS";
export const SET_NEW_ACCOUNT = "SET_NEW_ACCOUNT";
export const LOADING = "LOADING";
export const LOGIN_REDIRECT_URL = "LOGIN_REDIRECT_URL";
export const SET_PASSWORD_VERIFICATION_OTP = "SET_PASSWORD_VERIFICATION_OTP";
export const SET_PURCHASE_ID = "SET_PURCHASE_ID";
