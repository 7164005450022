import theAxios from "axios";
var CryptoJS = require("crypto-js");

const Axios = theAxios.create({
  baseURL: process.env.NEXT_PUBLIC_URL_API,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "client-secret": process.env.NEXT_PUBLIC_CLIENT_SECRET,
  },
});

Axios.interceptors.request.use(function (config) {
  config.headers["client-secret"] = process.env.NEXT_PUBLIC_CLIENT_SECRET;
  config.headers.common["client-secret"] = process.env.NEXT_PUBLIC_CLIENT_SECRET;
  config.headers.common["client-crypto-auth"] = CryptoJS.AES.encrypt(
    `web-${Date.parse(new Date().toISOString())}`,
    CryptoJS.enc.Utf8.parse(process.env.NEXT_PUBLIC_SECRET_KEY),
    { iv: CryptoJS.enc.Utf8.parse(process.env.NEXT_PUBLIC_IV), mode: CryptoJS.mode.CBC }
  ).toString();
  return config;
});

export const configureAxiosLocale = (locale: string = "en") => {
  Axios.defaults.headers.common["Content-Language"] = locale;
  Axios.defaults.headers.common["Accept-Language"] = locale;
};

export const configureAxiosAuth = (token?: string) => {
  Axios.defaults.headers.common["Authorization"] = token ? "Bearer " + token : "";
};

export default Axios;
