type Props = {
  margin?: string;
  color?: string;
  width?: string;
  height?: string;
  rotated?: boolean;
};

const LightArrowRightSvgIcon = ({ margin, color, width, height, rotated }: Props) => {
  return (
    <svg
      style={{ margin: margin ? margin : "0", transform: rotated && "rotate(180deg)" }}
      width={width ? width : "8"}
      height={height ? height : "13"}
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.18359 6.00106C7.18359 5.91859 7.1684 5.84047 7.13802 5.76668C7.10764 5.69724 7.06207 5.63213 7.0013 5.57137L1.83854 0.525798C1.72135 0.40861 1.5803 0.350017 1.41536 0.350017C1.30252 0.350017 1.20269 0.376059 1.11589 0.428142C1.02474 0.480225 0.953125 0.55184 0.901042 0.642985C0.844618 0.729791 0.816406 0.829618 0.816406 0.942465C0.816406 1.10306 0.875 1.24411 0.992188 1.36564L5.73177 6.00106L0.992188 10.6365C0.875 10.758 0.816406 10.8991 0.816406 11.0597C0.816406 11.1725 0.844618 11.2723 0.901042 11.3591C0.953125 11.4503 1.02474 11.5219 1.11589 11.574C1.20269 11.6261 1.30252 11.6521 1.41536 11.6521C1.5803 11.6521 1.72135 11.5957 1.83854 11.4828L7.0013 6.43075C7.06207 6.36998 7.10764 6.30271 7.13802 6.22892C7.1684 6.15948 7.18359 6.08352 7.18359 6.00106Z"
        fill={color ? color : "#ABABAB"}
      />
    </svg>
  );
};

export default LightArrowRightSvgIcon;
