import { rgba } from "@Utils/StyleUtil";
import styled from "styled-components";

export const SubjectBanner = styled.div<{
  readonly countryCode: string;
}>`
  display: inline-block;
  font-size: 1.4em;
  line-height: 1.42857143;
  padding: 0.2em 0.6em;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 2px 3px ${({ theme }) => rgba(theme.colors.black, 0.05)};
  border-radius: 0.4em;
  text-transform: uppercase;
  margin-bottom: 0.4em;
`;
