import { CommonButton } from "@Components/common/CommonButton";
import { mq, mw } from "@Utils/StyleUtil";
import styled, { css } from "styled-components";
import { LandingBGColors } from "types/common";

export const OuterWrapper = styled.div<{ readonly commonBG?: LandingBGColors }>`
  display: flex;
  align-items: center;
  height: 100%;
  transition: 0.9s ease background-color;
  padding: 10rem 0 0 0;
`;
export const Wrapper = styled.section`
  width: 100%;
`;
export const Inner = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 60px;
  ${mq(992)} {
    justify-content: center;
    margin-bottom: 40px;
  }
  ${mq(576)} {
    margin-bottom: 32px;
  }
`;
export const SectionTitle = styled.h2`
  font-size: 3.6rem;
  line-height: 1.55556;
  max-width: 464px;
  font-weight: 800;
  .underlined {
    background: url("/images/icons/underline.svg") no-repeat;
    background-position: center bottom;
    background-size: 100% auto;
    padding-bottom: 4px;
    &:lang(ar) {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      padding-bottom: 28px;
    }
  }
  ${mq(992)} {
    line-height: 1.57142857;
    text-align: center;
    font-size: 2.8rem;
    max-width: 339px;
  }
  ${mq(768)} {
    font-size: 2.4rem;
    line-height: 1.5;
    max-width: 306px;
  }
  ${mq(576)} {
    font-size: 2rem;
    line-height: 1.6;
    max-width: 242px;
  }
`;
export const ArrowsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  ${mq(992)} {
    display: none;
  }
`;
export const ArrowBtn = styled(CommonButton)`
  padding: 0;
  min-width: 60px;
  max-width: 60px;
  height: 60px;
  transition:
    0.2s ease opacity,
    0.2s ease background-color;
  svg {
    transform: none /*rtl: scale(-1, 1) */;
  }
  ${({ theme }) => css`
    background-color: ${theme.colors.darkSeagule};
    &:hover {
      background-color: ${theme.colors.darkSeagule};
    }
    &:active {
      background: ${theme.colors.darkSeagule};
    }
    &:disabled,
    &[disabled] {
      cursor: not-allowed;
      background-color: ${theme.colors.darkSeagule};
      opacity: 0.8;
    }
  `}
  &.inverse-dir {
    svg {
      transform: scale(-1, 1) /*rtl: none */;
    }
  }
`;
export const SliderWrapper = styled.div`
  .swiper-grid {
    padding: 0 10.55555556%;
    overflow: visible;
    ${mq(1200)} {
      padding: 0 10.16666666%;
    }
    ${mq(992)} {
      padding: 0 13.91129032%;
    }
    ${mq(768)} {
      padding: 0 15.10416666%;
    }
    ${mq(576)} {
      padding: 0 12px;
    }
  }
  .swiper-slide {
    width: 262px !important;
  }
`;
export const SubjectWrapper = styled.article`
  font-size: 10px;
  ${mq(992)} {
    font-size: 7.5px;
  }
`;
export const ShowMoreBtnIcon = styled.img`
  transform: none /*rtl:scale(-1, 1) */;
  height: 11px;
  width: 13.33px;
  object-fit: contain;
  ${mw(1920)} {
    width: calc(13.33px * 1.3);
    height: calc(11px * 1.3);
  }
`;

export const ShowMoreBtn = styled(CommonButton)`
  width: 100%;
  gap: 12px;
  min-width: 220px;
  margin: 60px 0;
  font-size: 1.8rem;
  line-height: 1.55555556;
  padding: 14px;
  font-weight: 500;
  cursor: pointer;
  ${mw(1920)} {
    min-width: calc(212px * 1.3);
    font-size: calc(1.6rem * 1.3);
  }
  ${mq(992)} {
    min-width: 212px;
    margin-top: 40px;
    font-size: 1.6rem;
    padding: 12px;
    line-height: 1.5;
  }
  ${mq(768)} {
    min-width: 212px;
    margin-top: 32px;
  }
  ${mq(576)} {
    min-width: 212px;
    margin-top: 32px;
  }
`;
