import { Models } from "@rematch/core";
import banner from "./Banner.model";
import themeType from "./Theme.model";
import user from "./User.model";

export interface RootModel extends Models<RootModel> {
  user: typeof user;
  banner: typeof banner;
  themeType: typeof themeType;
}

export const models: RootModel = { user, banner, themeType };
