type Props = {
  isOpen?: boolean;
  color?: string;
  width?: string;
  height?: string;
  margin?: string;
};

const DropdownSvgIcon = ({ isOpen, color, width, height, margin }: Props) => {
  return (
    <svg
      style={{
        margin: margin ? margin : "0",
        transition: "0.3s ease transform",
        transform: isOpen ? "rotate(-180deg)" : "rotate(0deg)",
      }}
      width={width ? width : "10"}
      height={height ? height : "6"}
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.56569 5.43431L9.63432 1.36569C10.1383 0.861714 9.78135 0 9.06863 0L0.931371 0C0.218647 0 -0.138286 0.861714 0.365686 1.36569L4.43431 5.43431C4.74673 5.74673 5.25327 5.74673 5.56569 5.43431Z"
        fill={color ? color : "#454545"}
      />
    </svg>
  );
};

export default DropdownSvgIcon;
