import Link from "@Components/common/Link";
import { rgba } from "@Utils/StyleUtil";
import Image from "next/image";
import styled from "styled-components";

export const SubjectCardContainer = styled(Link)``;

export const SubjectLabelsNumbers = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  font-size: ${({ theme }) => theme.fontsizes.body14};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.nero};
`;

export const SubjectCardImageWrapper = styled.div`
  position: relative;
`;

export const CardTagsWrapper = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export const CreatedAtTag = styled(SubjectLabelsNumbers)`
  padding: 0 8px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
`;

export const SubjectLabel = styled(CreatedAtTag)``;

export const SubjectRatingWrapper = styled.span`
  padding: 0 6px;
  display: flex;
  align-items: center;
  gap: 2px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const SubjectWatchProgressWrapper = styled(SubjectRatingWrapper)`
  /* position: absolute; */
  top: 12px;
  left: 12px;
  gap: 4px;
`;

export const WatchedPercentageNumber = styled(SubjectLabelsNumbers)``;

export const SubjectWatchWrapper = styled.span`
  padding: 0 6px;
  position: absolute;
  top: 80px;
  left: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
`;

export const SubjectRating = styled(SubjectLabelsNumbers)``;

export const SubjectDetailsWrapper = styled.div<{ readonly isLightTheme?: boolean }>`
  position: absolute;
  bottom: 12px;
  left: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  color: ${({ isLightTheme, theme }) => (isLightTheme ? theme.colors.white : theme.colors.nero)};
`;

export const SubjectCoursesNumber = styled(SubjectLabelsNumbers)`
  text-transform: capitalize;
  color: inherit;
`;

export const SubjectCardImage = styled(Image)`
  border-radius: 8px;
`;

export const SubjectCardImagePlaceholder = styled.div`
  margin-bottom: 12px;
  width: 262px;
  height: 196px;
  background-color: ${({ theme }) => theme.colors.gray4};
  border-radius: 8px;
`;

export const SubjectCardInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

export const CollageName = styled(SubjectLabelsNumbers)`
  color: ${({ theme }) => theme.colors.gray2};
`;

export const SubjectName = styled.p`
  margin-bottom: 4px;
  font-weight: ${({ theme }) => theme.fontWeight.font600};
  font-size: ${({ theme }) => theme.fontsizes.body18};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.nero};
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const TeacherName = styled(SubjectLabelsNumbers)``;

export const WatchedProgressWrapper = styled.div<{
  readonly color?: string;
  readonly width?: string;
  readonly height?: string;
}>`
  min-width: ${({ width }) => (width ? width : "18px")};
  max-width: ${({ width }) => (width ? width : "18px")};
  min-height: ${({ height }) => (height ? height : "18px")};
  max-height: ${({ height }) => (height ? height : "18px")};
  transform: rotate(185deg);
  &:lang(ar) {
    transform: rotate(175deg);
  }
  .circle-bg {
    fill: none;
    stroke: ${({ color }) => color && rgba(color, 0.15)};
    stroke-width: 4.5;
  }
  .circle {
    fill: none;
    stroke: ${({ color }) => color && color};
    stroke-width: 4.5;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
  }
  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }
`;
